import React, { useState } from 'react'
import Pagination from '../../../constant/Pagination';
import ExportExcelBtn from '../ExportBtn';
import SelectDatePeriod from '../SelectDatePeriod';
import SelectReportType from '../SelectReportType';
import { connect } from 'react-redux';
import TableLoaderEffect from '../../../constant/TableLoader';

const sheetHeader = ['Material Name', 'UCS Code', 'Total Stock IN']
const MostReceiveMaterialReport = ({ data, isLoading }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPage = 10;

    const handlePagination = (page) => {
        const new_page = page.selected + 1
        setCurrentPage(new_page)
    }
    return (
        <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting" style={{ minHeight: '80vh' }}>
            <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                <SelectReportType />
                <div className="d-flex justify-content-end align-items-end">
                    <SelectDatePeriod />
                    <ExportExcelBtn
                        headers={sheetHeader} />
                </div>

            </div>
            <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                    <thead>
                        <tr>
                            {sheetHeader.map((item, index) => (
                                <th key={index}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? <TableLoaderEffect colLength={sheetHeader.length} /> : data.slice((currentPage - 1) * recordsPage, currentPage * recordsPage).map((item, index) => (
                            <tr key={index}>
                                <td>{item.material_name}</td>
                                <td>{item.ucs_code}</td>
                                <td>{item.quantity}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Pagination
                    currentPage={currentPage}
                    recordsPage={recordsPage}
                    dataLength={data.length}
                    handlePagination={handlePagination}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        data: state.report.data,
        isLoading: state.report.isDataLoading
    };
};

export default connect(mapStateToProps)(MostReceiveMaterialReport);
