import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getRackProductsAction, handleSelectedViewStockAction, loadingToggleSingleRackAction } from '../../../store/actions/RackActions';
import Pagination from '../../constant/Pagination';
import DataLoader from '../Dashboard/Loader';

const ViewProduct = () => {
    let { id } = useParams();
    const store = useSelector(state => state.racks)
    const { selectRackProducts, selectProductStock, isLoadingSingleRack } = store
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPage = 10;

    useEffect(() => {
        dispatch(loadingToggleSingleRackAction(true))
        dispatch(getRackProductsAction(id))
    }, [dispatch, id])

    const handleViewStock = (prod_id) => {
        console.log("sss", prod_id)
        dispatch(handleSelectedViewStockAction(prod_id))
    }

    const handlePagination = (page) => {
        const new_page = page.selected + 1
        setCurrentPage(new_page)
    }

    return (isLoadingSingleRack ? <DataLoader height={"40rem"} marginTop={'20rem'} /> : 
        <>
            {selectRackProducts != null ? (
                <div className="container-fluid">
                    <div className="row">
                        <div className='col-xl-6'>
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                        <h4>Rack - {selectRackProducts.rack.rack_no}</h4>
                                        <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                            <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Material</th>
                                                        <th>UCS Code</th>
                                                        <th>Total Quantity</th>
                                                        <th>View Stock</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {selectRackProducts.products.slice((currentPage - 1) * recordsPage, currentPage * recordsPage).map((st, ind) => (
                                                        <tr key={ind}>
                                                            <td><Link to={`/material/${st.id}`}>{st.name}</Link></td>
                                                            <td><span>{st.ucs_code}</span></td>
                                                            <td><span>{st.net_quantity}</span></td>
                                                            <td>
                                                                <button type='button' onClick={() => { handleViewStock(st.id) }} className='btn btn-sm btn-outline-primary' disabled={st === selectProductStock}>View Stock</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                            <Pagination
                                                currentPage={currentPage}
                                                recordsPage={recordsPage}
                                                dataLength={selectRackProducts.products.length}
                                                handlePagination={handlePagination}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* col-end */}

                        <div className='col-xl-6'>
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                        <h4>Material - {selectProductStock?.name}</h4>
                                        <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                            <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Barcode</th>
                                                        <th>Quantity</th>
                                                        <th>Source</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {selectProductStock?.stocks.map((st, ind) => (
                                                        <tr key={ind}>
                                                            <td><span>{st.barcode__barcode_no}</span></td>
                                                            <td><span>{st.quantity}</span></td>
                                                            <td><span>{st.source}</span></td>
                                                            <td><span>{st.created_at}</span></td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : <h2>Invalid Rack Id {id}</h2>}
        </>
    );
};

export default ViewProduct;