import React from "react";
import ReactApexChart from "react-apexcharts";
import { Nav, Tab } from 'react-bootstrap';
import DataLoader from "../Loader";

const chartHeaderData = [
  { title: 'W', type: 'weekly' },
  { title: 'M', type: 'monthly' },
  { title: 'Y', type: 'yearly' },
];

const ProjectOverviewChart = ({ data, handleChange }) => {

  const options = {

    chart: {
      height: 300,
      type: 'line',
      stacked: false,
      toolbar: {
        show: false,
      },
    },

    stroke: {
      width: [0, 1, 1],
      curve: 'straight',
      dashArray: [0, 0, 5]
    },
    legend: {
      fontSize: '13px',
      fontFamily: 'poppins',
      labels: {
        colors: '#888888',
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '18%',
        borderRadius: 6,
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: "vertical",
        colorStops: [
          [
            {
              offset: 0,
              color: 'var(--bs-primary)',
              opacity: 1
            },
            {
              offset: 100,
              color: 'var(--bs-primary)',
              opacity: 1
            }
          ],
          [
            {
              offset: 0,
              color: '#3AC977',
              opacity: 1
            },
            {
              offset: 100,
              color: '#3AC977',
              opacity: 0
            }
          ],
          [
            {
              offset: 0,
              color: '#FF5E5E',
              opacity: 1
            },
            {
              offset: 100,
              color: '#FF5E5E',
              opacity: 1
            }
          ],
        ],
        stops: [0, 100, 100, 100]
      }
    },
    colors: ["var(--bs-primary)", "#3AC977", "#FF5E5E"],
    labels: data ? data.labels : [],
    markers: {
      size: 0
    },
    xaxis: {
      type: 'month',
      labels: {
        style: {
          fontSize: '13px',
          colors: '#888888',
        },
      },
    },
    yaxis: {
      min: 0,
      tickAmount: 4,
      labels: {
        style: {
          fontSize: '13px',
          colors: '#888888',
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " points";
          }
          return y;
        }
      }
    }
  }

  return (
    <div className="card">
      <Tab.Container defaultActiveKey={'W'}>
        <div className="card-header border-0 pb-0 flex-wrap">
          <h4 className="heading mb-0">Stocks Overview</h4>
          <Nav as="ul" className="nav nav-pills mix-chart-tab">
            {chartHeaderData.map((item, index) => (
              <Nav.Item as="li" className="nav-item" key={index}>
                <Nav.Link eventKey={item.title}
                  onClick={() => handleChange(item.type)}>
                  {item.title}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>
        <div className="card-body  p-0">
          {data ? <>
            {data?.total_product_quantity !== 0 ?
              <div id="overiewChart">
                <ReactApexChart
                  options={options}
                  series={data.series}
                  type="line"
                  height={300}
                />
              </div>
              : <div style={{ height: '5rem', marginTop: '3rem' }}>
                <h4 className="text-center">No Data Found! Change Filter </h4>
              </div>}
            <div className="ttl-project">
              <div className="pr-data">
                <h5 className="text-success">{data?.total_stock_in}</h5>
                <span>Total Stock IN</span>
              </div>
              <div className="pr-data">
                <h5 className="text-danger">{data?.total_stock_out}</h5>
                <span>Total Stock OUT</span>
              </div>
              <div className="pr-data">
                <h5 className="text-primary">{data?.total_product_quantity}</h5>
                <span>Total Stock</span>
              </div>
            </div>
          </> : <DataLoader />}
        </div>

      </Tab.Container>
    </div>
  );

}

export default ProjectOverviewChart;