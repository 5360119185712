import { SVGICON } from "../../constant/theme";

export const MenuList = [
    {   
        title:'Dashboard',
        iconStyle: SVGICON.MenuDashboard,
        to: '/dashboard',
        can_user_access:true,
    },
    {   
        title:'Racks',
        iconStyle: SVGICON.MenuRack,
        to: '/rack',
        can_user_access:true,
    },
    {   
        title:'Material',
        iconStyle: SVGICON.MenuMaterial,
        to: '/material',
        can_user_access:true,
    },
    {   
        title:'Stock History',
        iconStyle: SVGICON.MenuStockHistory,
        to: '/stock_history',
        can_user_access:true,
    },
    {   
        title:'Barcode',
        iconStyle: SVGICON.MenuBarcode,
        to: '/barcode',
        can_user_access:true,
    },
    {   
        title:'Employee',
        iconStyle: SVGICON.MenuEmployee,
        to: '/employee',
        can_user_access:true,
    },
    {   
        title:'Report',
        iconStyle: SVGICON.MenuReport,
        to: '/report',
        can_user_access:false,
    },
    {   
        title:'User',
        iconStyle: SVGICON.MenuUsers,
        to: '/user',
        can_user_access:false,
    },
    {   
        title:'Demand Forecast',
        iconStyle: SVGICON.Reports,
        to: '/demand-forecast',
        can_user_access:false,
    },
    {   
        title:'Analysis',
        iconStyle: SVGICON.Charts,
        to: '/analysis',
        can_user_access:false,
    }
    
]