import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Nav, Tab, Modal } from 'react-bootstrap';
const chartFilterData = [
  { title: '30 D', type: '30days' },
  { title: '1 Y', type: '1year' },
  { title: 'All', type: 'alltime' },
];

const FSNChart = ({ chartData, handleChange }) => {
  const { data, labels, product_data } = chartData

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState([])
  const [modalTitle, setModalTitle] = useState('')

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const handleChartClick = (label) => {
    setModalData(product_data[label])
    setModalTitle(`Category ${label} Data (FSN Graph)`)
    toggleModal()
  }

  const series = [{ name: 'Products', data }]
  const options = {

    chart: {
      height: 300,
      type: 'bar',
      stacked: false,
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          if (config.dataPointIndex !== undefined && config.seriesIndex !== undefined) {
            handleChartClick(labels[config.dataPointIndex])
          }
        }
      }
    },

    // stroke: {
    //     width: [0, 1, 1],
    //     curve: 'straight',
    //     dashArray: [0, 0, 5]
    // },
    legend: {
      fontSize: '13px',
      fontFamily: 'poppins',
      labels: {
        colors: '#888888',
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '30%',
        borderRadius: 6,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    colors:['#47B39C'],
    xaxis: {
      categories: labels,
      type: 'month',
      labels: {
        style: {
          fontSize: '13px',
          colors: '#888888',
        },
      },
    },
    yaxis: {
      min: 0,
      tickAmount: 4,
      labels: {
        style: {
          fontSize: '13px',
          colors: '#888888',
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " Products";
          }
          return y;
        }
      }
    }
  }

  return (
    <>
      <div className="card">
        <div className="card-header border-0 pb-0 flex-wrap">
          <h3 className="mb-0">Inventory Velocity(FSN) Chart</h3>
          <Tab.Container defaultActiveKey={'alltime'}>
            <Nav as="ul" className="nav nav-pills mix-chart-tab">
              {chartFilterData.map((item, index) => (
                <Nav.Item as="li" className="nav-item" key={index}>
                  <Nav.Link eventKey={item.type}
                    onClick={() => handleChange(item.type)}>
                    {item.title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Tab.Container>
        </div>
        <div className="card-body  p-0">
          <div id="overiewChart">
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={300}
            />
          </div>
        </div>
      </div>
      <Modal className="modal modal-lg fade" id="exampleModal3" scrollable={true} show={isModalOpen} onHide={() => toggleModal()}>
        <div className="modal-content">
          <div className="modal-header ">
            <h5 className="modal-title">{modalTitle}</h5>
            <button type="button" className="btn-close" onClick={() => toggleModal()}></button>
          </div>
          <div className="modal-body">
            <div className="table-responsive active-projects style-1">
              <div id="customer-tblwrapper" className="dataTables_wrapper no-footer">
                <table id="empoloyees-tbl1" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Percentage</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalData.map((item, index) => (
                      <tr key={index}>
                        <td><span>{item.name}</span></td>
                        <td>
                          <span>{item.percentage} %</span>
                        </td>
                        <td>
                          <span>{item.quantity}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );

}

export default FSNChart;