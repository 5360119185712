export const HANDLE_SELETED_REPORT_TYPE = '[Report Action] Handle Selected Report Type';
export const HANDLE_FROM_TO_DATE = '[Report Action] Handle From To Date';
export const HANDLE_SELECTED_PERIOD = '[Report Action] Handle Selected Period';
export const GET_DATA_BY_REPORT_TYPE = '[Report Action] Get Data By Report type';
export const GET_MATERIAL_OPTIONS = '[Report Action] Get Material Options';
export const HANDLE_SELETED_MATERIAL = '[Report Action] Handle Selected Material';
export const GET_EMPLOYEE_OPTIONS = '[Report Action] Get Employee Options';
export const HANDLE_SELETED_EMPLOYEE = '[Report Action] Handle Selected Employee';
export const GET_PURPOSE_OPTIONS = '[Report Action] Get Purpose Options';
export const HANDLE_SELETED_PURPOSE = '[Report Action] Handle Selected Purpose';
export const LOADING_DATA_ACTION = '[Report Action] Toggle Data Loading';
export const SHOW_TOAST_ERROR = '[Report Action] Show Toast Error';
