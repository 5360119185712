import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Modal } from 'react-bootstrap';


const SDEChart = ({ chartData }) => {
    const { data, labels, product_data } = chartData

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalData, setModalData] = useState([])
    const [modalTitle, setModalTitle] = useState('')

    const toggleModal = () => setIsModalOpen(!isModalOpen)

    const handleChartClick = (label) => {
        setModalData(product_data[label])
        setModalTitle(`Category ${label} Data (ABC Graph)`)
        toggleModal()
    }

    const options = {

        chart: {
            width: '100%',
            type: 'pie',
            height: 300,
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    if (config.dataPointIndex !== undefined && config.seriesIndex !== undefined) {
                        handleChartClick(labels[config.dataPointIndex])
                    }
                }
            }

        },
        labels,
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: -5
                }
            }
        },

        dataLabels: {
            formatter(val, opts) {
                const name = opts.w.globals.labels[opts.seriesIndex]
                return [name, `${Math.round(val)} %`]
            }
        },
        legend: {
            show: false
        }
    }

    return (
        <>
            <div className="card">
                <div className="card-header border-0 pb-0 flex-wrap">
                    <h3 className="mb-0">Procurement Complexity(SDE) Chart</h3>
                </div>
                <div className="card-body  p-0">
                    <div id="overiewChart">
                        <ReactApexChart
                            options={options}
                            series={data}
                            type="pie"
                            height={300}
                        />
                    </div>
                </div>
            </div>

            <Modal className="modal modal-lg fade" id="exampleModal3" scrollable={true} show={isModalOpen} onHide={() => toggleModal()}>
                <div className="modal-content">
                    <div className="modal-header ">
                        <h5 className="modal-title">{modalTitle}</h5>
                        <button type="button" className="btn-close" onClick={() => toggleModal()}></button>
                    </div>
                    <div className="modal-body">
                        <div className="table-responsive active-projects style-1">
                            <div id="customer-tblwrapper" className="dataTables_wrapper no-footer">
                                <table id="empoloyees-tbl1" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Percentage</th>
                                            <th>Lead Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {modalData.map((item, index) => (
                                            <tr key={index}>
                                                <td><span>{item.name}</span></td>
                                                <td>
                                                    <span>{item.percentage} %</span>
                                                </td>
                                                <td>
                                                    <span>{item.lead_time} Days</span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );

}

export default SDEChart;