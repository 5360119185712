import Select from "react-select";
import { useEffect } from "react";
import { handleSelectedPurposeAction, handleDataLoadingAction, getDataByReportTypeAction,getPurposeOptionAction } from '../../../store/actions/ReportActions';
import { connect, useDispatch } from 'react-redux';


const PurposeType = ({ selectedPurpose, purposeOtions }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getPurposeOptionAction())
    }, [dispatch])
    return (
        <div style={{ minWidth: '12rem' }} className="mx-2">
            <label className="form-label">Select Purpose</label>
            <Select
                value={selectedPurpose}
                onChange={(data) => {
                    dispatch(handleSelectedPurposeAction(data))
                    dispatch(handleDataLoadingAction(true))
                    dispatch(getDataByReportTypeAction())
                }}
                isMulti={true}
                placeholder={"Choose Purpose"}
                className='react-select'
                classNamePrefix='select'
                styles={{ minWidth: '10rem' }}
                options={purposeOtions}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        selectedPurpose: state.report.selectedPurpose,
        purposeOtions: state.report.purposeOtions
    };
};
export default connect(mapStateToProps)(PurposeType);