import * as XLSX from 'xlsx';
import { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment'

const ExportExcelBtn = ({ data, reportType, fromDate, toDate, headers}) => {
    const [isExporting, setIsExporting] = useState(false)

    const exportToExcel = () => {
        try {
            setIsExporting(true)
            let timePeriod = `${moment(fromDate).format("MMM Do YYYY")} To ${moment(toDate).format("MMM Do YYYY")}`
            if (reportType.value === "critical_reorder_item") {
                timePeriod = null
            }
            const excelData = [
                [`${reportType.label.toUpperCase()} REPORT`]
            ]
            if (timePeriod) {
                excelData.push([`Date - ${timePeriod}`])
            }
            excelData.push([], headers, ...data.map(item => Object.values(item)))
            const ws = XLSX.utils.aoa_to_sheet(excelData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Report Sheet');
            XLSX.writeFile(wb, `${reportType.value}_report.xlsx`);
        } catch (error) {
            console.log("error", error)
        }
        setIsExporting(false)
    }

    return (
        <div>
            {data.length ? (
                <button className='btn btn-success' disabled={isExporting} onClick={exportToExcel}>
                    {isExporting ? "Exporting ..." : "Export"}
                    <i className="fa-solid fa-file-excel ms-2"></i>
                </button>
            ) : null}
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        data: state.report.data,
        reportType: state.report.selectedReportType,
        fromDate: state.report.fromDate,
        toDate: state.report.toDate
    };
};

export default connect(mapStateToProps)(ExportExcelBtn);
