import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Select from 'react-select'
import moment from 'moment'
import { handleFromToDateAction, getDataByReportTypeAction, handleDataLoadingAction, handleSelectedPeriodAction } from '../../../store/actions/ReportActions';
import { connect, useDispatch } from 'react-redux';

const periodOptions = [
    { value: '15', label: 'Last 15 Days' },
    { value: '30', label: 'Last Month' },
    { value: '90', label: 'Last 3 Months' },
    { value: 'custom', label: 'Custom Date' }
]

const SelectDatePeriod = ({ fromDate, toDate, selectedPeriod }) => {
    const dispatch = useDispatch()
    const handleCallback = (start, end) => {
        const dateTo = end.format('YYYY-MM-DD')
        const dateFrom= start.format('YYYY-MM-DD')
        dispatch(handleFromToDateAction({ fromDate: dateFrom, toDate: dateTo }))
        dispatch(handleDataLoadingAction(true))
        dispatch(getDataByReportTypeAction())
    }

    const handlePeriod = (data) => {
        let date_from = null
        if (data.value === '15') {
            date_from = moment().subtract(15, 'days').format('YYYY-MM-DD')
        } else if (data.value === '30') {
            date_from = moment().subtract(30, 'days').format('YYYY-MM-DD')
        } else if (data.value === '90') {
            date_from = moment().subtract(90, 'days').format('YYYY-MM-DD')
        }
        if (date_from) {
            const date_to = moment().format('YYYY-MM-DD')
            dispatch(handleFromToDateAction({ fromDate: date_from, toDate: date_to }))
            dispatch(handleDataLoadingAction(true))
            dispatch(getDataByReportTypeAction())
        }
        dispatch(handleSelectedPeriodAction(data))

    }

    return (
        <div className="mx-2 d-flex">
            {selectedPeriod.value === "custom" ? (
                <div className="mx-2">
                    <label>Select Custom Period</label>
                    <DateRangePicker
                        initialSettings={{
                            startDate: fromDate,
                            endDate: toDate,
                            locale: {
                                format: 'YYYY-MM-DD', // Set the desired date format
                            },
                        }}
                        onCallback={handleCallback}
                    >
                        <input type="text" className="form-control input-daterange-timepicker" />
                    </DateRangePicker>
                </div>
            ) : null}
            <div className="mx-2">
                <label>Select Period</label>
                <Select
                    id='period_filter'
                    isClearable={false}
                    className='react-select'
                    classNamePrefix='select'
                    options={periodOptions}
                    value={selectedPeriod}
                    onChange={(data) => { handlePeriod(data) }}
                />
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        fromDate: state.report.fromDate,
        toDate: state.report.toDate,
        selectedPeriod:state.report.selectedPeriod
    };
};
export default connect(mapStateToProps)(SelectDatePeriod);