import React, { useState, useEffect } from 'react';
import { getEmployeeList, createEmployee, updateEmployee } from '../../../services/OtherService';
import Pagination from '../../constant/Pagination';
import { toast } from 'react-toastify';
import { Offcanvas, Form } from 'react-bootstrap';
import { CancelRequest } from '../../../services/AxiosInstance';
import TableLoaderEffect from '../../constant/TableLoader';


const EmployeePage = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState({ open: false, type: 'Add' });
    const [empForm, setEmpForm] = useState({ name: '', personnel_number: '', phone: '' });
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const recordsPage = 10;

    useEffect(() => {
        setIsLoading(true)
        getEmployeeList(currentPage, recordsPage, searchQuery).then((resp) => {
            setData(resp.data.data)
            setTotal(resp.data.total)
            setIsLoading(false)
        }).catch((error) => {
            console.log(error.response.data.message)
            setIsLoading(false)
        })

    }, [])

    const toggleModal = (type = "Add", emp = null) => {
        if (type === "Edit" && data) {
            setEmpForm({
                employee_id: emp.id, name: emp.name,
                personnel_number: emp.personnel_number, phone: emp.phone || ''
            });
        }
        if (isModalOpen.open) {
            setEmpForm({ name: '', personnel_number: '', phone: '' });
        }
        setIsModalOpen({ type, open: !isModalOpen.open })
    }

    const handleChangeAdd = (e) => {
        const { name, value } = e.target
        setEmpForm({ ...empForm, [name]: value })
    }

    const handlePagination = (page) => {
        setIsLoading(true)
        const new_page = page.selected + 1
        getEmployeeList(new_page, recordsPage, '').then((resp) => {
            setData(resp.data.data)
            setTotal(resp.data.total)
            setCurrentPage(new_page)
            setIsLoading(false)
        }).catch((error) => {
            alert(error.response.data.message)
            setIsLoading(false)
        })
    }

    const handleSearchChange = (e) => {
        const { value } = e.target
        if (value.length > 0) {
            CancelRequest()
        }
        getEmployeeList(currentPage, recordsPage, value).then((resp) => {
            if (resp) {
                setData(resp.data.data)
                setTotal(resp.data.total)
            }
        }).catch((error) => {
            console.log(error.response.data.message)
        })
        setSearchQuery(value)
    }



    const handleSubmit = async (e) => {
        e.preventDefault()
        if (isModalOpen.type === "Add") {
            try {
                const resp = await createEmployee(empForm)
                const resp_data = resp.data.data
                setData([resp_data, ...data])
                setTotal(total + 1)
                toast.success(resp.data.message)
                toggleModal()
            } catch (error) {
                toast.error(error.response.data.message)
            };
        } else {
            try {
                const response = await updateEmployee(empForm)
                const resp_data = response.data
                setData(data.map(user => user.id === resp_data.data.id ? { ...resp_data.data } : user))
                toast.success(resp_data.message)
                toggleModal()
            } catch (error) {
                console.log(error)
                if (error && error.response) {
                    toast.error(error.response.data.message)
                }
            };
        }



    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">Employee</h4>
                                        <div className='d-flex'>
                                            <div className='form-group me-5'>
                                                <input type="search" name="serach" className="form-control "
                                                    placeholder="Search" autoFocus
                                                    value={searchQuery} onChange={handleSearchChange} />
                                            </div>
                                            <button className="btn btn-primary btn-sm ms-1" data-bs-toggle="offcanvas"
                                                onClick={() => toggleModal()}
                                            >+ Add Employee</button>
                                        </div>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Personnel Number</th>
                                                    <th>Mobile</th>
                                                    <th>Total Material Issued</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ? <TableLoaderEffect colLength={9} /> : data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><span className='text-primary cursor-pointer fw-bolder' onClick={() => toggleModal("Edit", item)}>{item.name}</span></td>
                                                        <td>{item.personnel_number}</td>
                                                        <td><span>{item.phone || "None"}</span></td>
                                                        <td><span>{item.material}</span></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        {isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                                            currentPage={currentPage}
                                            recordsPage={recordsPage}
                                            dataLength={total}
                                            handlePagination={handlePagination}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* add employee */}
            <Offcanvas show={isModalOpen.open && isModalOpen.type === "Add"}
                onHide={() => toggleModal()}
                onExited={() => console.log("exied")}
                className="offcanvas-end" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title" id="#gridSystemModal">Add Employee</h5>
                    <button type="button" className="btn-close"
                        onClick={() => toggleModal()}>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <div className="container">
                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Employee Name <span className="text-danger">*</span></label>
                                        <input type="text" autoFocus className="form-control" id="name" placeholder="Enter Name"
                                            value={empForm.name} name='name' onChange={handleChangeAdd} required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="personnel_number" className="form-label">Personnel Number <span className="text-danger">*</span></label>
                                        <input type="number" className="form-control" id="personnel_number" placeholder="Enter personnel number"
                                            value={empForm.personnel_number} name='personnel_number' onChange={handleChangeAdd} required />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label">Mobile Number <span className="text-light">(Optional)</span></label>
                                        <input type="number"
                                            className="form-control"
                                            id="phone"
                                            placeholder="Enter Mobile Number"
                                            value={empForm.phone}
                                            name='phone'
                                            onChange={handleChangeAdd} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary me-1">Submit</button>
                                <button type="button" onClick={() => toggleModal()} className="btn btn-danger light ms-1">Cancel</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Offcanvas>

            {/* add employee end */}

            {/* edit employee */}
            <Offcanvas show={isModalOpen.open && isModalOpen.type === "Edit"}
                onHide={() => toggleModal()}
                className="offcanvas-end" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title" id="#gridSystemModal">Edit Employee</h5>
                    <button type="button" className="btn-close"
                        onClick={() => toggleModal()}>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <div className="container">
                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Employee Name <span className="text-danger">*</span></label>
                                        <input type="text" autoFocus className="form-control" id="name" placeholder="Enter Name"
                                            value={empForm.name} name='name' onChange={handleChangeAdd} required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="personnel_number" className="form-label">Personnel Number <span className="text-danger">*</span></label>
                                        <input type="number" className="form-control" id="personnel_number" placeholder="Enter personnel number"
                                            value={empForm.personnel_number} name='personnel_number' onChange={handleChangeAdd} required />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label">Mobile Number <span className="text-light">(Optional)</span></label>
                                        <input type="number"
                                            className="form-control"
                                            id="phone"
                                            placeholder="Enter Mobile Number"
                                            value={empForm.phone}
                                            name='phone'
                                            onChange={handleChangeAdd} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary me-1">Submit</button>
                                <button type="button" onClick={() => toggleModal()} className="btn btn-danger light ms-1">Cancel</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Offcanvas>

            {/* edit employee end */}

        </>
    );
};
export default EmployeePage;