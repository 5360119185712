import Select from "react-select";
import { handleSelectedReportTypeAction, getDataByReportTypeAction, handleDataLoadingAction } from '../../../store/actions/ReportActions';
import { connect, useDispatch } from 'react-redux';

const reportOption = [
    { label: 'Material', value: 'material' },
    { label: 'Material Transaction', value: 'material_transaction' },
    { label: 'Employee', value: 'employee' },
    { label: 'Material usage', value: 'material_usage' },
    { label: 'Critical Reorder Item', value: 'critical_reorder_item' },
    { label: 'Most Received Material', value: 'most_received_material' },
    { label: 'Fast Moving Material', value: 'fast_moving_material' },
    { label: 'Dead Stock Item', value: 'dead_stock_item' }
]

const SelectReportType = ({ selectedReportType }) => {
    const dispatch = useDispatch()
    return (
        <div style={{ minWidth: '12rem', maxWidth:'15rem' }}>
            <label className="form-label">Report Type</label>
            <Select
                value={selectedReportType}
                onChange={(data) => {
                    dispatch(handleSelectedReportTypeAction(data))
                    dispatch(handleDataLoadingAction(true))
                    dispatch(getDataByReportTypeAction())
                }}
                className='react-select'
                classNamePrefix='select'
                styles={{ minWidth: '10rem' }}
                options={reportOption}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        selectedReportType: state.report.selectedReportType,
    };
};
export default connect(mapStateToProps)(SelectReportType);