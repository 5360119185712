import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import MaterialReport from './ReportList/MaterialReport';
import MaterialTransactionReport from './ReportList/MaterialTransactionReport';
import SelectReportType from './SelectReportType';
import MostMovingMaterialReport from './ReportList/MostMovingMaterialReport';
import MostReceiveMaterialReport from './ReportList/MostReceiveMaterialReport';
import CriticalReorderMaterialReport from './ReportList/CriticalReorderMaterialReport';
import { getDataByReportTypeAction, handleDataLoadingAction } from '../../../store/actions/ReportActions';
import EmplyeeReport from './ReportList/EmplyeeReport';
import MaterialUsageReport from './ReportList/MaterialUsageReport';
import DeadStockItemReport from './ReportList/DeadStockItemReport';
const StockReport = ({ selectedReportType }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(handleDataLoadingAction(true))
        dispatch(getDataByReportTypeAction())
    }, [dispatch])
    let content;
    switch (selectedReportType.value) {
        case 'material':
            content = <MaterialReport />;
            break;
        case 'material_transaction':
            content = <MaterialTransactionReport />;
            break;
        case 'fast_moving_material':
            content = <MostMovingMaterialReport />;
            break;
        case 'most_received_material':
            content = <MostReceiveMaterialReport />;
            break;
        case 'critical_reorder_item':
            content = <CriticalReorderMaterialReport />;
            break;
        case 'employee':
            content = <EmplyeeReport />;
            break;
        case 'material_usage':
            content = <MaterialUsageReport />;
            break;
        case 'dead_stock_item':
            content = <DeadStockItemReport />;
            break;
        default:
            content = (<div>
                <SelectReportType />
                Invalid Report Type
            </div>);
    }
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        selectedReportType: state.report.selectedReportType
    };
};

export default connect(mapStateToProps)(StockReport);