import { Suspense, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { useDispatch } from 'react-redux';
// action
import { checkAutoLoginAction } from './store/actions/AuthActions';
/// Style
import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkAutoLoginAction());
  }, []);


  return (
    <Suspense fallback={
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    }
    >
      <Index />
    </Suspense>
  );
};


export default App;



