import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getRackLIstAction, loadingToggleAction, modalToggleAction, createRackAction, updateRackAction } from '../../../store/actions/RackActions';
import Pagination from '../../constant/Pagination';
import TableLoaderEffect from '../../constant/TableLoader'

const Racks = () => {
    const store = useSelector(state => state.racks)
    const { racks, totals, isModalOpen, modalType, isLoading } = store
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const recordsPage = 10;
    const dispatch = useDispatch()
    const [addForm, setAddForm] = useState({ rack_no: '', barcode_no: '' })
    const [editForm, setEditForm] = useState({ rack_no: '', barcode_no: '' })

    useEffect(() => {
        dispatch(loadingToggleAction(true))
        dispatch(getRackLIstAction(currentPage, recordsPage, searchQuery))
    }, [dispatch])

    const handlePagination = (page) => {
        const new_page = page.selected + 1
        dispatch(getRackLIstAction(new_page, recordsPage, searchQuery))
        setCurrentPage(new_page)
    }


    const handleSearchChange = (e) => {
        const { value } = e.target
        dispatch(getRackLIstAction(currentPage, recordsPage, value))
        setSearchQuery(value)
    }

    const toggleAddModal = (modal_type="Add", item = null) => {
        if (item) {
            if (modal_type === "Edit") {
                setEditForm({ rack_no: item.rack_no, barcode_no: item.barcode_no, rack_id: item.id })
            }
        }
        if (!isModalOpen) {
            if (modal_type === "Add") {
                setAddForm({ rack_no: '', barcode_no: '' })
            }
        }
        dispatch(modalToggleAction(modal_type))

    }


    const handleChangeAdd = (e) => {
        const { name, value } = e.target
        setAddForm({ ...addForm, [name]: value })
    }

    const handleChangeEdit = (e) => {
        const { name, value } = e.target
        setEditForm({ ...editForm, [name]: value })
    }

    const handleAddSubmit = (e) => {
        e.preventDefault()
        if (Object.values(addForm).some(itm => itm.length === 0)) {
            alert("Required All Data")
            return false
        } else {
            dispatch(createRackAction(addForm))
        }
    }

    const handleEditSubmit = (e) => {
        e.preventDefault()
        if (Object.values(editForm).some(itm => itm.length === 0)) {
            alert("Required All Data")
            return false
        } else {
            dispatch(updateRackAction(editForm))
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">Racks</h4>
                                        <div className='d-flex align-items-center'>
                                            <div className='form-group me-4'>
                                                <input type="search" name="serach" className="form-control "
                                                    placeholder="Search" autoFocus
                                                    value={searchQuery} onChange={handleSearchChange} />
                                            </div>

                                            <button className="btn btn-primary ms-1" data-bs-toggle="offcanvas"
                                                onClick={() => toggleAddModal("Add")}
                                            >Add Racks</button>
                                        </div>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Rack Name</th>
                                                    <th>Barcode NO</th>
                                                    <th>Material Count</th>
                                                    {/* <th>Most Stocked Material </th> */}
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ? <TableLoaderEffect colLength={4} /> : racks.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <Link to={`/rack/${item.id}`} className="fw-bold text-primary">{item.rack_no}</Link>
                                                        </td>
                                                        <td><span>{item.barcode_no}</span></td>
                                                        <td><span>{item.product}</span></td>
                                                        {/* <td><span>{item.most_stock_product}</span></td> */}
                                                        <td>
                                                            <button type="button" className="btn btn-outline-primary btn-sm me-3" onClick={() => toggleAddModal("Edit", item)}>Edit</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>
                                        {isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                                            currentPage={currentPage}
                                            recordsPage={recordsPage}
                                            dataLength={totals}
                                            handlePagination={handlePagination}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* add rack modal */}
            <Modal className="modal fade" id="exampleModal1" show={isModalOpen && modalType === "Add"} onHide={() => toggleAddModal("Add")}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">Add New Rack</h1>
                        <button type="button" className="btn-close" onClick={() => toggleAddModal("Add")}></button>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={(e) => { handleAddSubmit(e) }} method='POST'>
                            <div className="row">
                                <div className="col-xl-12">
                                    <label className="form-label">Rack No<span className="text-danger">*</span></label>
                                    <input type="text" autoFocus name="rack_no" className="form-control mb-3"
                                        placeholder="Enter Rack Number" required
                                        value={addForm.rack_no} onChange={handleChangeAdd} />

                                    <label className="form-label">Barcode No<span className="text-danger">*</span></label>
                                    <input type="text" name="barcode_no" className="form-control"
                                        placeholder="Scan Barcode No" required
                                        value={addForm.barcode_no} onChange={handleChangeAdd} />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mt-3 float-end">Add Rack</button>
                        </Form>
                    </div>
                </div>
            </Modal>

            {/* edit rack */}

            <Modal className="modal fade" id="exampleModal1" show={isModalOpen && modalType === "Edit"} onHide={() => toggleAddModal("Edit")}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">Edit Rack</h1>
                        <button type="button" className="btn-close" onClick={() => toggleAddModal("Edit")}></button>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={(e) => { handleEditSubmit(e) }} method='POST'>
                            <div className="row">
                                <div className="col-xl-12">
                                    <label className="form-label">Rack No<span className="text-danger">*</span></label>
                                    <input type="text" name="rack_no" className="form-control mb-3"
                                        placeholder="Enter Rack Number" required
                                        value={editForm.rack_no} onChange={handleChangeEdit} />

                                    <label className="form-label">Barcode No<span className="text-danger">*</span></label>
                                    <input type="text" name="barcode_no" className="form-control"
                                        placeholder="Scan Barcode No" required
                                        value={editForm.barcode_no} onChange={handleChangeEdit} />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mt-3 float-end">Update Rack</button>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Racks;