import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Modal, Nav, Tab } from "react-bootstrap";
const chartFilterData = [
  { title: '1 M', type: '1M' },
  { title: '3 M', type: '3M' },
  { title: '6 M', type: '6M' },
  { title: '1 Y', type: '1Y' },
];

const StockRatioChart = ({ chartData, handleChange }) => {
  const { data, labels, product_data } = chartData

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState([])
  const [modalTitle, setModalTitle] = useState('')

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const handleChartClick = () => {
    setModalData(product_data)
    setModalTitle(`Stock Ratio Graph Data`)
    toggleModal()
  }

  const series = data

  const options = {
    chart: {
      height: 350,
      type: 'radialBar',
      offsetY: -10,
      events: {
        dataPointSelection: (event, chartContext, config) => {
          handleChartClick()
        }
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: '16px',
            color: undefined,
            offsetY: 120
          },
          value: {
            offsetY: 76,
            fontSize: '22px',
            color: undefined,
            formatter: function (val) {
              return val + "%";
            }
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        shadeIntensity: 0.15,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91]
      },
    },
    stroke: {
      dashArray: 4
    },
    labels
  };

  return (
    <>
      <div className="card">
        <div className="card-header border-0 pb-0 flex-wrap">
          <h3 className="mb-0">Inventory Ageing Chart</h3>
          <Tab.Container defaultActiveKey={'1M'}>
            <Nav as="ul" className="nav nav-pills mix-chart-tab">
              {chartFilterData.map((item, index) => (
                <Nav.Item as="li" className="nav-item" key={index}>
                  <Nav.Link eventKey={item.type}
                    onClick={() => handleChange(item.type)}>
                    {item.title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Tab.Container>
        </div>
        <div className="card-body  p-0">
          <div id="overiewChart">
            <ReactApexChart
              options={options}
              series={series}
              type="radialBar"
              height={300}
            />
          </div>
        </div>
      </div>
      <Modal className="modal modal-lg fade" id="exampleModal3" scrollable={true} show={isModalOpen} onHide={() => toggleModal()}>
        <div className="modal-content">
          <div className="modal-header ">
            <h5 className="modal-title">{modalTitle}</h5>
            <button type="button" className="btn-close" onClick={() => toggleModal()}></button>
          </div>
          <div className="modal-body">
            <div className="table-responsive active-projects style-1">
              <div id="customer-tblwrapper" className="dataTables_wrapper no-footer">
                <table id="empoloyees-tbl1" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalData.map((item, index) => (
                      <tr key={index}>
                        <td><span>{item.product__name}</span></td>
                        <td>
                          <span>{item.quantity} </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );

}

export default StockRatioChart;