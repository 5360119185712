import React, { useState } from 'react';
import { getStockByBarcode, stockOutProduct } from '../../../services/ProductService';
import { searchEmployee, searchPurpose } from '../../../services/RackService';
import { createEmployee } from '../../../services/OtherService';
import { Modal, Spinner, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable'

function StockOutBtn() {
  const emptyStock = {
    stock_id: '',
    barcode_no: '',
    select_quantity: '',
    net_quantity: '',
    product: '',
    employee: null,
    purpose: null,
    showRmConf: false
  }
  const [modalOpen, setModalOpen] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [stock, setStock] = useState([emptyStock])

  const [empModalOpen, setEmpModalOpen] = useState(false)
  const [isEmpSubmitLoading, setIsEmpSubmitLoading] = useState(false)
  const [empForm, setEmpForm] = useState({ name: '', personnel_number: '', phone: '' });
  const [stockIndex, setStockIndex] = useState(0); // for create employee to add employee data
  const [purposeOption, setPurposeOption] = useState([])
  const [optionsEmployee, setOptionsEmployee] = useState([])
  const [isEmployeeFetching, setIsEmployeeFetching] = useState(false)
  const [isPurposeFetching, setIsPurposeFetching] = useState(false)

  const toggleEmpModal = () => {
    if (empModalOpen) {
      setEmpForm({ name: '', personnel_number: '', phone: '' })
    }
    setEmpModalOpen(!empModalOpen)

  }

  const handleChangeEmpInput = (e) => {
    const { name, value } = e.target
    setEmpForm({ ...empForm, [name]: value })
  }

  const handleEmpSubmit = async (e) => {
    e.preventDefault()
    try {
      if (!isEmpSubmitLoading) {
        setIsEmpSubmitLoading(true)
        const resp = await createEmployee(empForm)
        const resp_data = resp.data.data
        const empOption = { value: resp_data.id, label: `${resp_data.name} (${resp_data.personnel_number})` }
        setOptionsEmployee([empOption, ...optionsEmployee])
        let newStock = [...stock]
        newStock[stockIndex] = { ...newStock[stockIndex], employee: empOption, employeeInput: '' }
        setStock([...newStock]);
        toast.success(resp.data.message)
        setIsEmpSubmitLoading(false)
        toggleEmpModal()
      }
    } catch (error) {
      toast.error(error.response.data.message)
    };
  }

  const toggleModal = () => {
    if (modalOpen) {
      setStock([emptyStock])
      setPurposeOption([])
      setOptionsEmployee([])
    } else {
      fetchEmployeeOptions()
      fetchPurposeOptions()
    }
    setModalOpen(!modalOpen)
  }

  const handleInputChange = (value, index) => {
    let newStock = [...stock]
    newStock[index] = { ...newStock[index], barcode_no: value, stock_id: '' }
    setStock([...newStock]);
  };

  const handleQuantityChange = (value, index) => {
    const elem = document.getElementById(`select_quantity-${index}`)
    let newStock = [...stock]
    const min = 1; // Set your minimum value here
    const max = newStock[index].net_quantity; // Set your maximum value here
    // Check if the input is within the specified range
    if (value === '' || (value >= min && value <= max)) {
      newStock[index] = { ...newStock[index], select_quantity: value }
      setStock([...newStock]);
      elem?.classList.remove('is-invalid')
    } else {
      elem?.classList.add('is-invalid')
    }
  };


  const hasDuplicatesStock = () => {
    const idSet = new Set();
    for (const s of stock) {
      if (idSet.has(s.barcode_no)) {
        return true; // Found a duplicate id
      }
      idSet.add(s.barcode_no);
    }
    return false; // No duplicates found
  };

  const handleKeyPress = async (e, index) => {
    if (e.key === 'Enter' && e.target.value.length > 0) {
      if (hasDuplicatesStock()) {
        toast.error("Barcode already exists in stock OUT list")
      } else {
        try {
          const response = await getStockByBarcode(e.target.value);
          const { data } = response.data
          let newStock = [...stock]
          newStock[index] = { ...newStock[index], stock_id: data.id, select_quantity: data.quantity, net_quantity: data.quantity, product: data.product }
          setStock([...newStock]);
        } catch (error) {
          toast.error(error.response.data.message)
        }
      }
    }
  };

  const validateStocks = () => {
    return stock.map((item, index) => {
      const validList = ['barcode_no', 'select_quantity', 'employee', 'purpose']
      return Object.entries(item).map(([key, value]) => {
        if (validList.includes(key)) {
          if (value === null || value.length === 0) {
            const elem = document.getElementById(`${key}-${index}`)
            elem.classList.add('is-invalid')
            elem.focus()
            return false
          }
          return true
        }
        return true
      }).every(val => val === true)

    })
  }

  const addStock = () => {
    if (validateStocks().every(value => value === true)) {
      setStock(stock.concat(emptyStock))
    }
  }

  const removeStock = (index) => {
    const newStock = [...stock]
    newStock.splice(index, 1)
    setStock([...newStock])
  }

  const removeConfirm = (index, toggleVal) => {
    const newStock = [...stock]
    newStock[index] = { ...newStock[index], showRmConf: toggleVal }
    setStock([...newStock])
  }

  const onSubmitStockOUT = () => {
    if (!isSubmitLoading) {
      if (validateStocks().every(value => value === true)) {
        const data = stock.filter(item => item.select_quantity > 0 && item.select_quantity <= item.net_quantity).map(st => {
          return { stock_id: st.stock_id, select_quantity: Number(st.select_quantity), employee_id: st.employee.value, purpose: st.purpose.value }
        })
        setIsSubmitLoading(true)
        stockOutProduct({ stock_list: data }).then((response) => {
          toast.success(response.data.message)
          toggleModal()
        }).catch((error) => {
          toast.error(error.response.data.message)
        });
        setIsSubmitLoading(false)
      }
    }
  }

  const LoadingIndicator = () => {
    return (
      <Spinner color='primary' size={'sm'} delay={0} />
    )
  }

  async function fetchEmployeeOptions() {
    try {
      setIsEmployeeFetching(true)
      const resp = await searchEmployee()
      setOptionsEmployee(resp.data.data);
    }
    catch (error) {
      console.log("EROR =>", error)
    } finally {
      setIsEmployeeFetching(false)
    }
  };

  async function fetchPurposeOptions() {
    try {
      setIsPurposeFetching(true)
      const resp = await searchPurpose()
      setPurposeOption(resp.data.data);
    }
    catch (error) {
      console.log("EROR =>", error)
    } finally {
      setIsPurposeFetching(false)
    }
  };

  const handlePurposeChange = (data, index) => {
    try {
      let newStock = [...stock]
      newStock[index] = { ...newStock[index], purpose: { value: data.value, label: data.label } }
      setStock([...newStock]);
      document.getElementById(`purpose-${index}`)?.classList.remove('is-invalid')
    } catch (error) {
      console.log(error.response.data.message)
    }
  }

  const handleEmployeeChange = (data, index) => {
    try {
      let newStock = [...stock]
      if (data.__isNew__) {
        setStockIndex(index)
        toggleEmpModal()
        setEmpForm({ ...empForm, name: data.value })
        newStock[index] = { ...newStock[index], employeeInput: '' }
      } else {
        newStock[index] = { ...newStock[index], employee: { value: data.value, label: data.label } }
      }
      setStock([...newStock]);
      document.getElementById(`employee-${index}`)?.classList.remove('is-invalid')
    } catch (error) {
      console.log(error.response.data.message)
    }
  }

  return (
    <>
      <button className="btn btn-danger mx-2" onClick={toggleModal}> Stock OUT <i className="fa-solid fa-square-up-right ms-2"></i> </button>
      {/* stock out modal */}

      <Modal className="modal fade" id="exampleModal3"
        show={modalOpen}
        onHide={toggleModal}
        fullscreen={true}
        backdrop="static"
        keyboard={false} >
        <div className="modal-content">
          <div className="modal-header ">
            <h3 className="modal-title">Stock OUT</h3>
            <button type="button" className="btn btn-danger light btn-sm" onClick={toggleModal}>
              <i className="fa-solid  fa-lg fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className='table-responsive active-projects' style={{ minHeight: '80vh' }}>
              <div id="employee-tbl_wrapper" className="dataTables_wrapper active-projects no-footer">
                <table id="empoloyees-tblwrapper" className="table table-striped ItemsCheckboxSec dataTable no-footer mb-0">
                  <thead>
                    <tr>
                      <th>Barcode</th>
                      <th>Quantity</th>
                      <th>Material</th>
                      <th>Employee</th>
                      <th>Purpose</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stock.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <input type="text"
                            id={`barcode_no-${index}`}
                            style={{ width: '10rem' }}
                            autoFocus={item.barcode_no.length === 0}
                            value={item.barcode_no}
                            onChange={(e) => { handleInputChange(e.target.value, index) }}
                            onKeyPress={(e) => { handleKeyPress(e, index) }}
                            className="form-control" placeholder="Barcode" />
                        </td>
                        {item.stock_id ? (<>
                          <td>
                            <div className='input-group' style={{ minWidth: '8rem', maxWidth: '10rem' }}>
                              <input type="number"
                                min={1}
                                max={item.net_quantity}
                                autoFocus={true}
                                onChange={(e) => { handleQuantityChange(e.target.value, index) }}
                                value={item.select_quantity}
                                className="form-control" id={`select_quantity-${index}`} placeholder="Quantity" />
                              <span className="input-group-text">{item.net_quantity}</span>
                            </div>
                          </td>
                          <td><span className='text-primary'>{item.product}</span></td>
                          <td>
                            <div style={{ width: '18rem' }}>
                              <CreatableSelect
                                value={item.employee}
                                onChange={(data) => handleEmployeeChange(data, index)}
                                placeholder={"Select Employee"}
                                isClearable={false}
                                isLoading={isEmployeeFetching}
                                options={optionsEmployee}
                                id={`employee-${index}`}
                                className='react-select'
                                classNamePrefix='select'
                              />
                            </div>
                          </td>
                          <td>
                            <div style={{ width: '12rem' }}>
                              <CreatableSelect
                                isClearable={false}
                                id={`purpose-${index}`}
                                value={item.purpose}
                                className='react-select'
                                classNamePrefix='select'
                                placeholder="Select Purpose"
                                options={purposeOption}
                                isLoading={isPurposeFetching}
                                onChange={(data) => { handlePurposeChange(data, index) }}
                                components={{ LoadingIndicator }}
                              />
                            </div>
                          </td>
                        </>) : (<>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </>)}
                        <td>
                          {item.stock_id.length && (stock.length === index + 1) ? (
                            <button className='btn btn-sm me-2 btn-outline-success rounded' onClick={addStock}><i className="fa-solid fa-lg fa-plus"></i></button>
                          ) : null}
                          {stock.length > 1 ? item.showRmConf ? (
                            <div className="d-flex align-items-center flex-column">
                              <h6>Confirm Remove</h6>
                              <div className='d-flex justify-content-around align-items-center'>
                                <span className='c-pointer badge badge-outline-success badge-sm'
                                  onClick={() => removeStock(index)}>
                                  <i class="fa-solid fa-check"></i>
                                </span>
                                <span className='c-pointer badge badge-outline-danger badge-sm ms-3'
                                  onClick={() => removeConfirm(index, false)}>
                                  <i class="fa-solid fa-xmark"></i>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <button className='btn btn-sm btn-outline-danger rounded'
                              onClick={() => {
                                item.stock_id.length > 0 ? removeConfirm(index, true) : removeStock(index)
                              }}>
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-primary btn-sm" disabled={isSubmitLoading} onClick={onSubmitStockOUT}>
              {isSubmitLoading ? (<>
                <LoadingIndicator /> Stock OUT
              </>) : "Stock OUT"}
            </button>
            <button type="button" className="btn btn-danger light btn-sm" disabled={isSubmitLoading} onClick={toggleModal}>Close</button>
          </div>
        </div>
      </Modal>
      {/* create emplppyee modal */}
      <Modal className="modal  fade" id="exampleModal3"
        show={empModalOpen}
        onHide={toggleEmpModal}
        backdrop="static"
        keyboard={false} >
        <div className="modal-content modal-sm">
          <div className="modal-header ">
            <h3 className="modal-title">Create Employee</h3>
            <button type="button" className="btn btn-danger light btn-sm" onClick={toggleEmpModal}>
              <i className="fa-solid  fa-lg fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={(e) => handleEmpSubmit(e)}>
              <div className="row">
                <div className="col-xl-12">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Employee Name <span className="text-danger">*</span></label>
                    <input type="text" autoFocus className="form-control" id="name" placeholder="Enter Name"
                      value={empForm.name} name='name' onChange={handleChangeEmpInput} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="personnel_number" className="form-label">Personnel Number <span className="text-danger">*</span></label>
                    <input type="number" className="form-control" id="personnel_number" placeholder="Enter personnel number"
                      value={empForm.personnel_number} name='personnel_number' onChange={handleChangeEmpInput} required />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">Mobile Number <span className="text-light">(Optional)</span></label>
                    <input type="number"
                      className="form-control"
                      id="phone"
                      placeholder="Enter Mobile Number"
                      value={empForm.phone}
                      name='phone'
                      onChange={handleChangeEmpInput} />
                  </div>
                </div>
              </div>
              <div>
                <button type="submit" className="btn btn-primary me-1" disabled={isEmpSubmitLoading}>
                  {isEmpSubmitLoading ? (<>
                    <LoadingIndicator /> Create
                  </>) : "create"}
                </button>
                <button type="button" disabled={isEmpSubmitLoading} onClick={() => toggleEmpModal()} className="btn btn-danger light ms-1">Cancel</button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default StockOutBtn;