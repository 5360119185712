import {
    createProduct,
    getProduct,
    getProductList,
    deleteProduct,
    updateProduct,
    stockInProduct
} from '../../services/ProductService';
import { CancelRequest } from '../../services/AxiosInstance';
import {
    CONFIRMED_CREATE_PRODUCT_ACTION,
    CONFIRMED_DELETE_PRODUCT_ACTION,
    CONFIRMED_EDIT_PRODUCT_ACTION,
    CONFIRMED_GET_PRODUCT,
    CONFIRMED_GET_PRODUCT_LIST,
    CONFIRMED_PRODUCT_STOCK_IN_ACTION,
    LOADING_PRODUCT_ACTION,
    MODAL_TOGGLE_ACTION,
    SHOW_TOAST_ERROR,
    HANDLE_SELETED_PRODUCT,
    LOADING_SINGLE_PRODUCT_ACTION
} from './ProductTypes';

export function deleteProductAction(rackId, history) {
    return (dispatch, getState) => {
        deleteProduct(rackId).then((response) => {
            dispatch(confirmedDeleteProductAction(rackId));
        }).catch((error) => {
            dispatch(showToastError(error.response.data.message))
        });
    };
}

export function confirmedDeleteProductAction(rackId) {
    return {
        type: CONFIRMED_DELETE_PRODUCT_ACTION,
        payload: rackId,
    };
}

export function createProductAction(rackData) {

    return (dispatch, getState) => {
        createProduct(rackData).then((response) => {
            dispatch(confirmedCreateProductAction(response.data.data));
        }).catch((error) => {
            dispatch(showToastError(error.response.data.message))
        });
    };
}

export function updateProductAction(data) {
    return (dispatch, getState) => {
        updateProduct(data).then((reponse) => {
            dispatch(confirmedUpdateProductAction(reponse.data.data));
        }).catch((error) => {
            dispatch(showToastError(error.response.data.message))
        });

    };
}

export function getProductAction(prodId) {
    return (dispatch, getState) => {
        getProduct(prodId).then((response) => {
            dispatch(confirmedGetProductsAction(response.data.data));
        }).catch((error) => {
            dispatch(showToastError(error.response.data.message))
        });
    };
}

export function getProductLIstAction(page, page_size, query, stock_type) {
    if (query.length > 0) {
        CancelRequest()
    }
    return (dispatch, getState) => {
        getProductList(page, page_size, query, stock_type).then((response) => {
            const data = response ? response.data : null
            dispatch(confirmedGetProductListAction(data));
        });
    };
}

export function ProductStockInAction(stockData) {

    return (dispatch, getState) => {
        stockInProduct(stockData).then((response) => {
            dispatch(confirmedProductStockInAction(response.data.data));
        }).catch((error) => {
            dispatch(showToastError(error.response.data.message))
        });
    };
}

export function confirmedCreateProductAction(singleProduct) {

    return {
        type: CONFIRMED_CREATE_PRODUCT_ACTION,
        payload: singleProduct,
    };
}

export function confirmedGetProductsAction(posts) {
    return {
        type: CONFIRMED_GET_PRODUCT,
        payload: posts,
    };
}

export function confirmedGetProductListAction(posts) {
    return {
        type: CONFIRMED_GET_PRODUCT_LIST,
        payload: posts,
    };
}

export function confirmedUpdateProductAction(post) {

    return {
        type: CONFIRMED_EDIT_PRODUCT_ACTION,
        payload: post,
    };
}

export function confirmedProductStockInAction(post) {

    return {
        type: CONFIRMED_PRODUCT_STOCK_IN_ACTION,
        payload: post,
    };
}


export function handleSelectedProductAction(rack_id) {

    return {
        type: HANDLE_SELETED_PRODUCT,
        payload: rack_id,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_PRODUCT_ACTION,
        payload: status,
    };
}
export function loadingToggleSingleProductAction(status) {
    return {
        type: LOADING_SINGLE_PRODUCT_ACTION,
        payload: status,
    };
}

export function modalToggleAction(modalType) {
    return {
        type: MODAL_TOGGLE_ACTION,
        payload: modalType,
    };
}
export function showToastError(error) {
    return {
        type: SHOW_TOAST_ERROR,
        payload: error,
    };
}

