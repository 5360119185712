import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Nav, Tab, Modal } from 'react-bootstrap';

const chartFilterData = [
    { title: '30 D', type: '30days' },
    { title: '1 Y', type: '1year' },
    { title: 'All', type: 'alltime' },
];

const HMLChart = ({ chartData, handleChange }) => {
    const { data, labels, product_data } = chartData

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalData, setModalData] = useState([])
    const [modalTitle, setModalTitle] = useState('')

    const toggleModal = () => setIsModalOpen(!isModalOpen)

    const handleChartClick = (label) => {
        setModalData(product_data[label])
        setModalTitle(`Category ${label} Data (HML Graph)`)
        toggleModal()
    }
    function formatNumberToKMB(number) {
        if (number < 1000) {
          return number.toString();
        } else if (number < 1000000) {
          return (number / 1000).toFixed(1) + 'k';
        } else if (number < 1000000000) {
          return (number / 1000000).toFixed(1) + 'M';
        } else {
          return (number / 1000000000).toFixed(1) + 'B';
        }
      }

    const options = {

        chart: {
            width: '100%',
            type: 'pie',
            height: 300,
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    // Check if the selected data point is a bar
                    if (config.dataPointIndex !== undefined && config.seriesIndex !== undefined) {
                        handleChartClick(labels[config.dataPointIndex])
                    }
                }
            }
        },
        labels,
        colors:['#39F3BB', '#15607A', '#18A1CD'],
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: -5
                }
            }
        },

        dataLabels: {
            formatter(val, opts) {
                const name = opts.w.globals.labels[opts.seriesIndex]
                return [name, `${Math.round(val)} %`]
            }
        },
        legend: {
            show: false
        }
    }

    return (
        <>
            <div className="card">
                <div className="card-header border-0 pb-0 flex-wrap">
                    <h3 className="mb-0">Stock Importance(HML) Chart</h3>
                    <Tab.Container defaultActiveKey={'alltime'}>
                        <Nav as="ul" className="nav nav-pills mix-chart-tab">
                            {chartFilterData.map((item, index) => (
                                <Nav.Item as="li" className="nav-item" key={index}>
                                    <Nav.Link eventKey={item.type}
                                        onClick={() => handleChange(item.type)}>
                                        {item.title}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Tab.Container>
                </div>
                <div className="card-body  p-0">
                    <div id="overiewChart">
                        <ReactApexChart
                            options={options}
                            series={data}
                            type="pie"
                            height={300}
                        />
                    </div>
                </div>
            </div>
            <Modal className="modal modal-lg fade" id="exampleModal3" scrollable={true} show={isModalOpen} onHide={() => toggleModal()}>
                <div className="modal-content">
                    <div className="modal-header ">
                        <h5 className="modal-title">{modalTitle}</h5>
                        <button type="button" className="btn-close" onClick={() => toggleModal()}></button>
                    </div>
                    <div className="modal-body">
                        <div className="table-responsive active-projects style-1">
                            <div id="customer-tblwrapper" className="dataTables_wrapper no-footer">
                                <table id="empoloyees-tbl1" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Percentage</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {modalData.map((item, index) => (
                                            <tr key={index}>
                                                <td><span>{item.name}</span></td>
                                                <td>
                                                    <span>{item.percentage} %</span>
                                                </td>
                                                <td>
                                                    <span>₹ {formatNumberToKMB(item.price)}</span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );

}

export default HMLChart;