import Select from "react-select";
import { useEffect } from "react";
import { handleSelectedEmployeeAction, handleDataLoadingAction, getDataByReportTypeAction,getEmployeeOptionAction } from '../../../store/actions/ReportActions';
import { connect, useDispatch } from 'react-redux';


const EmployeeType = ({ selectedEmployee, employeeOtions }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getEmployeeOptionAction())
    }, [dispatch])
    return (
        <div style={{ minWidth: '12rem' }} className="mx-2">
            <label className="form-label">Select Employee</label>
            <Select
                value={selectedEmployee}
                onChange={(data) => {
                    dispatch(handleSelectedEmployeeAction(data))
                    dispatch(handleDataLoadingAction(true))
                    dispatch(getDataByReportTypeAction())
                }}
                isMulti={true}
                placeholder={"Choose Employee"}
                className='react-select'
                classNamePrefix='select'
                styles={{ minWidth: '10rem' }}
                options={employeeOtions}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        selectedEmployee: state.report.selectedEmployee,
        employeeOtions: state.report.employeeOtions
    };
};
export default connect(mapStateToProps)(EmployeeType);