import {
    CONFIRMED_CREATE_RACK_ACTION,
    CONFIRMED_DELETE_RACK_ACTION,
    CONFIRMED_EDIT_RACK_ACTION,
    CONFIRMED_GET_RACK,
    CONFIRMED_GET_RACK_LIST,
    CREATE_RACK_ACTION,
    LOADING_RACK_ACTION,
    MODAL_TOGGLE_ACTION,
    SHOW_TOAST_ERROR,
    HANDLE_SELETED_RACK,
    CONFIRMED_GET_RACK_PRODUCTS,
    HANDLE_SELETED_VIEW_STOCK,
    LOADING_SINGLE_RACK_ACTION
} from '../actions/RackTypes';
import { toast } from 'react-toastify';

const initialState = {
    racks: [],
    totals: 0,
    selectRack: null,
    selectRackProducts: null,
    selectProductStock: null,
    isModalOpen: false,
    modalType: "Add",
    isLoading: false,
    isLoadingSingleRack: false
};

function RackReducer(state = initialState, actions) {

    if (actions.type === LOADING_RACK_ACTION) {
        return {
            ...state,
            isLoading: actions.payload,
        };
    } else if (actions.type === LOADING_SINGLE_RACK_ACTION) {
        return {
            ...state,
            isLoadingSingleRack: actions.payload,
        };
    }

    else if (actions.type === MODAL_TOGGLE_ACTION) {
        return {
            ...state,
            isModalOpen: !state.isModalOpen,
            modalType: actions.payload
        };
    }

    else if (actions.type === CREATE_RACK_ACTION) {
        const post = {
            id: Math.random(),
            title: 'Post Title 2asdasd',
            description: 'Sample Description 2asdasdas',
        };

        const posts = [...state.posts];
        posts.push(post);
        return {
            ...state,
            posts,
        };
    }

    else if (actions.type === CONFIRMED_DELETE_RACK_ACTION) {
        const racks = [...state.racks];
        const rackIndex = racks.findIndex(
            (rack) => rack.id === actions.payload,
        );
        racks.splice(rackIndex, 1);
        toast.success("Rack Deleted Successfully")
        return {
            ...state,
            racks,
            isModalOpen: !state.isModalOpen
        };
    }

    else if (actions.type === CONFIRMED_EDIT_RACK_ACTION) {
        const racks = [...state.racks];
        const postIndex = racks.findIndex((rack) => rack.id === actions.payload.id,);
        racks[postIndex] = actions.payload;
        toast.success("Rack Updated successfully")
        return {
            ...state,
            racks,
            isModalOpen: !state.isModalOpen
        };
    }

    else if (actions.type === CONFIRMED_CREATE_RACK_ACTION) {
        toast.success("Rack created successfully")
        return {
            ...state,
            racks: [actions.payload, ...state.racks],
            isModalOpen: !state.isModalOpen,
        };
    }

    else if (actions.type === CONFIRMED_GET_RACK) {
        return {
            ...state,
            selectRack: actions.payload,
        };
    }

    else if (actions.type === HANDLE_SELETED_RACK) {
        let selected = null
        if (actions.payload) {
            selected = state.racks.filter(itm => itm.id === actions.payload)[0]
        }
        return {
            ...state,
            selectRack: selected,
        };
    }
    else if (actions.type === HANDLE_SELETED_VIEW_STOCK) {
        let selected = null
        if (actions.payload && state.selectRackProducts) {
            selected = state.selectRackProducts?.products.filter(itm => itm.id === actions.payload)[0]
        }
        return {
            ...state,
            selectProductStock: selected,
        };
    }

    else if (actions.type === CONFIRMED_GET_RACK_LIST) {
        if (actions.payload) {
            return {
                ...state,
                racks: actions.payload.data,
                totals: actions.payload.total,
                isLoading: false
            };
        }
        return {
            ...state
        };
    }

    else if (actions.type === CONFIRMED_GET_RACK_PRODUCTS) {
        const data = actions.payload
        return {
            ...state,
            selectRackProducts: data,
            selectProductStock: data?.products.length ? data.products[0] : null,
            isLoadingSingleRack:false

        };
    }

    if (actions.type === SHOW_TOAST_ERROR) {
        toast.error(actions.payload)
        return {
            ...state,
        };
    }

    return state;
}

export default RackReducer;