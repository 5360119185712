import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getProductStockHistoryList } from '../../../services/ProductService';
import Pagination from '../../constant/Pagination';

const ProductHistory = () => {
    const { id } = useParams()
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState([])
    const [product, setProduct] = useState(null)
    const [total, setTotal] = useState(0)
    const recordsPage = 10;

    useEffect(() => {
        getProductStockHistoryList(id, currentPage, recordsPage, '').then((resp) => {
            const { total, product, data } = resp.data
            setData(data)
            setTotal(total)
            setProduct(product)
        }).catch((error) => {
            console.log(error.response.data.message)
        })

    }, [id])

    const handlePagination = (page) => {
        const new_page = page.selected + 1
        getProductStockHistoryList(id, new_page, recordsPage, '').then((resp) => {
            const { total, product, data } = resp.data
            setData(data)
            setTotal(total)
            setProduct(product)
            setCurrentPage(new_page)
        }).catch((error) => {
            alert(error.response.data.message)
        })
    }

    const handleSearchChange = (e) => {
        const { value } = e.target
        getProductStockHistoryList(id, currentPage, recordsPage, value).then((resp) => {
            const { total, product, data } = resp.data
            setData(data)
            setTotal(total)
            setProduct(product)
        }).catch((error) => {
            console.log(error.response.data.message)
        })
        setSearchQuery(value)
    }


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">Stock History of <Link to={`/material/${id}`} className='text-primary'> {product?.name}</Link></h4>
                                        <div className='d-flex'>
                                            <div className='form-group me-5'>
                                                <input type="search" name="serach" className="form-control "
                                                    placeholder="Search" autoFocus
                                                    value={searchQuery} onChange={handleSearchChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Rack</th>
                                                    <th>User</th>
                                                    <th>Quantity</th>
                                                    <th>Product Quantity</th>
                                                    <th>Employee</th>
                                                    <th>Source</th>
                                                    <th>Status</th>
                                                    <th>Barcode</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><span>{item.stock.rack ? <Link to={`/rack/${item.stock.rack.id}`}>{item.stock.rack.rack_no}</Link> : null}</span></td>
                                                        <td><span>{item.user.name}</span></td>
                                                        <td>
                                                            <span>{item.quantity}</span>
                                                        </td>
                                                        <td>
                                                            <span>{item.product_quantity}</span>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex flex-column">
                                                                <span>{item?.employee?.name || ""}</span>
                                                                <small>{item?.purpose || ""}</small>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span>{item.stock.source}</span>
                                                        </td>
                                                        <td>
                                                            {item.is_stock_out ? (
                                                                <span className={`badge light border-0 badge-danger`}>OUT</span>
                                                            ) : (
                                                                <span className={`badge light border-0 badge-success`}>IN</span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <span>{item.stock.barcode_no}</span>
                                                        </td>
                                                        <td>
                                                            <span>{item.created_at}</span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        <Pagination
                                            currentPage={currentPage}
                                            recordsPage={recordsPage}
                                            dataLength={total}
                                            handlePagination={handlePagination}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ProductHistory;