import React from "react";
import ReactApexChart from "react-apexcharts";
import { Nav, Tab } from 'react-bootstrap';
import DataLoader from "../Loader";

const chartHeaderData = [
  { title: 'W', type: 'weekly' },
  { title: 'M', type: 'monthly' },
  { title: 'Y', type: 'yearly' },
];

const TopPieChart = ({ chartData, handleChange }) => {
  const data = chartData?.length > 10 ? chartData.slice(0, 5) : chartData
  const options = {

    chart: {
      width: '100%',
      type: 'polarArea',
      height: 300,
    },
    labels: data ? data?.map(item => item.name) : [],
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5
        }
      }
    },

    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex]
        return [name]
      }
    },
    legend: {
      show: false
    }
  }

  return (
    <div className="card" >
      <Tab.Container defaultActiveKey={'W'}>
        <div className="card-header border-0 pb-0 flex-wrap">
          <h4 className="heading mb-0">Fast Moving Items</h4>
          <Nav as="ul" className="nav nav-pills mix-chart-tab">
            {chartHeaderData.map((item, index) => (
              <Nav.Item as="li" className="nav-item" key={index}>
                <Nav.Link eventKey={item.title}
                  onClick={() => handleChange(item.type)}>
                  {item.title}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>
        <div className="card-body  p-0">
          {data.length ?
            <div id="overiewChart">
              <ReactApexChart
                options={options}
                series={data?.map(item => item.quantity)}
                type="polarArea"
                height={300}
              />
            </div>
            : <DataLoader />
          }
        </div>
      </Tab.Container>
    </div>
  );

}

export default TopPieChart;