import {
    GET_DATA_BY_REPORT_TYPE,
    HANDLE_SELETED_MATERIAL,
    HANDLE_SELETED_REPORT_TYPE,
    LOADING_DATA_ACTION,
    HANDLE_FROM_TO_DATE,
    SHOW_TOAST_ERROR,
    HANDLE_SELECTED_PERIOD,
    GET_MATERIAL_OPTIONS,
    GET_EMPLOYEE_OPTIONS,
    HANDLE_SELETED_EMPLOYEE,
    GET_PURPOSE_OPTIONS,
    HANDLE_SELETED_PURPOSE
} from '../actions/ReportTypes';
import moment from 'moment'

const initialState = {
    data: [],
    selectedReportType: { label: 'Material', value: 'material' },
    selectedPeriod: { value: '15', label: 'Last 15 Days' },
    isDataLoading: false,
    fromDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    materialOtions: [],
    selectedMaterial: [],
    employeeOtions: [],
    selectedEmployee: [],
    purposeOtions: [],
    selectedPurpose: []
};


function ReportReducer(state = initialState, actions) {

    if (actions.type === GET_DATA_BY_REPORT_TYPE) {
        return {
            ...state,
            data: actions.payload,
            isDataLoading:false
        };
    }
    else if (actions.type === HANDLE_SELETED_REPORT_TYPE) {
        return {
            ...state,
            fromDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
            selectedPeriod: { value: '15', label: 'Last 15 Days' },
            materialOtions: [],
            selectedMaterial: [],
            employeeOtions: [],
            selectedEmployee: [],
            purposeOtions: [],
            selectedPurpose: [],
            selectedReportType: actions.payload,
            data: [],
        };
    } else if (actions.type === HANDLE_SELECTED_PERIOD) {
        return {
            ...state,
            selectedPeriod: actions.payload,
        };
    }
    else if (actions.type === HANDLE_FROM_TO_DATE) {
        const { fromDate, toDate } = actions.payload
        return {
            ...state,
            fromDate,
            toDate
        };
    }
    else if (actions.type === LOADING_DATA_ACTION) {
        return {
            ...state,
            isDataLoading: actions.payload,
        };
    }
    else if (actions.type === SHOW_TOAST_ERROR) {
        console.error(actions.payload)
        return {
            ...state,
        };
    }
    else if (actions.type === GET_MATERIAL_OPTIONS) {
        return {
            ...state,
            materialOtions: actions.payload
        };
    }
    else if (actions.type === HANDLE_SELETED_MATERIAL) {
        return {
            ...state,
            selectedMaterial: actions.payload,
        };
    } else if (actions.type === GET_EMPLOYEE_OPTIONS) {
        return {
            ...state,
            employeeOtions: actions.payload
        };
    }
    else if (actions.type === HANDLE_SELETED_EMPLOYEE) {
        return {
            ...state,
            selectedEmployee: actions.payload,
        };
    } else if (actions.type === GET_PURPOSE_OPTIONS) {
        return {
            ...state,
            purposeOtions: actions.payload
        };
    }
    else if (actions.type === HANDLE_SELETED_PURPOSE) {
        return {
            ...state,
            selectedPurpose: actions.payload,
        };
    }
    return state;
}

export default ReportReducer;
