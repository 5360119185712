import {
    createRack,
    getRack,
    getRackList,
    deleteRack,
    updateRack,
    getRackProducts
} from '../../services/RackService';
import { CancelRequest } from '../../services/AxiosInstance';
import {
    CONFIRMED_CREATE_RACK_ACTION,
    CONFIRMED_DELETE_RACK_ACTION,
    CONFIRMED_EDIT_RACK_ACTION,
    CONFIRMED_GET_RACK,
    CONFIRMED_GET_RACK_LIST,
    LOADING_RACK_ACTION,
    LOADING_SINGLE_RACK_ACTION,
    MODAL_TOGGLE_ACTION,
    SHOW_TOAST_ERROR,
    HANDLE_SELETED_RACK,
    CONFIRMED_GET_RACK_PRODUCTS,
    HANDLE_SELETED_VIEW_STOCK
} from '../actions/RackTypes';

export function deleteRackAction(rackId) {
    return (dispatch, getState) => {
        deleteRack(rackId).then((response) => {
            dispatch(confirmedDeleteRackAction(rackId));
        }).catch((error) => {
            dispatch(showToastError(error.response.data.message))
        });;
    };
}
export function getRackProductsAction(rackId) {
    return (dispatch, getState) => {
        getRackProducts(rackId).then((response) => {
            dispatch(confirmGetRackProductsAction(response.data.data));
        }).catch((error) => {
            dispatch(showToastError(error.response.data.message))
        });;
    };
}

export function confirmedDeleteRackAction(rackId) {
    return {
        type: CONFIRMED_DELETE_RACK_ACTION,
        payload: rackId,
    };
}

export function createRackAction(rackData) {

    return (dispatch, getState) => {
        createRack(rackData).then((response) => {
            dispatch(confirmedCreateRackAction(response.data.data));
        }).catch((error) => {
            dispatch(showToastError(error.response.data.message))
        });
    };
}

export function updateRackAction(rack) {
    return (dispatch, getState) => {
        updateRack(rack).then((response) => {
            dispatch(confirmedUpdateRackAction(response.data.data));
        }).catch((error) => {
            dispatch(showToastError(error.response.data.message))
        });

    };
}

export function getRackAction() {
    return (dispatch, getState) => {
        getRack().then((response) => {
            dispatch(confirmedGetRacksAction(response.data));
        });
    };
}

export function getRackLIstAction(page, page_size, query) {
    if (query.length > 0) {
        CancelRequest()
    }
    return (dispatch, getState) => {
        getRackList(page, page_size, query).then((response) => {
            const data = response ? response.data : null
            dispatch(confirmedGetRackListAction(data));
        }).catch((error) => {
            dispatch(showToastError(error.response.data.message))
        });
    };
}

export function confirmedCreateRackAction(singleRack) {

    return {
        type: CONFIRMED_CREATE_RACK_ACTION,
        payload: singleRack,
    };
}

export function confirmedGetRacksAction(posts) {
    return {
        type: CONFIRMED_GET_RACK,
        payload: posts,
    };
}


export function confirmedGetRackListAction(posts) {
    return {
        type: CONFIRMED_GET_RACK_LIST,
        payload: posts,
    };
}

export function confirmGetRackProductsAction(data) {
    return {
        type: CONFIRMED_GET_RACK_PRODUCTS,
        payload: data,
    };
}


export function confirmedUpdateRackAction(post) {

    return {
        type: CONFIRMED_EDIT_RACK_ACTION,
        payload: post,
    };
}

export function handleSelectedRackAction(rack_id) {

    return {
        type: HANDLE_SELETED_RACK,
        payload: rack_id,
    };
}

export function handleSelectedViewStockAction(prod_id) {

    return {
        type: HANDLE_SELETED_VIEW_STOCK,
        payload: prod_id,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_RACK_ACTION,
        payload: status,
    };
}

export function loadingToggleSingleRackAction(status) {
    return {
        type: LOADING_SINGLE_RACK_ACTION,
        payload: status,
    };
}

export function modalToggleAction(modalType) {
    return {
        type: MODAL_TOGGLE_ACTION,
        payload: modalType,
    };
}
export function showToastError(error) {
    return {
        type: SHOW_TOAST_ERROR,
        payload: error,
    };
}

