import React, { useEffect, useState } from 'react';

//Import Components
import { VEDAnalysisApi, ABCAnalysisApi, HMLAnalysisApi, FSNAnalysisApi, SDEAnalysisApi, StockRatioAnalysisApi, getTopProductForecastList } from '../../../services/ProductService';
import ABCChart from './ABC';
import VEDChart from './VED';
import HMLChart from './HML';
import FSNChart from './FSN';
import SDEChart from './SDE';
import StockRatioChart from './StockAge';
import ForecastChart from '../Dashboard/elements/ProductForecastChart';

const monthNames = [
	"January", "February", "March", "April", "May", "June",
	"July", "August", "September", "October", "November", "December"
];

const Analysis = () => {
	const [vedData, setVEDData] = useState(null)
	const [abcData, setABCData] = useState(null)
	const [hmlData, setHMLData] = useState(null)
	const [fsnData, setFSNData] = useState(null)
	const [sdeData, setSDEData] = useState(null)
	const [stockRData, setStockRData] = useState(null)
	const [topForecastData, setTopForecastData] = useState(null);


    useEffect(() => {
        ABCAnalysisApi("All").then((response) => {
			setABCData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});

		VEDAnalysisApi().then((response) => {
			setVEDData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});
		HMLAnalysisApi().then((response) => {
			setHMLData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});
		FSNAnalysisApi().then((response) => {
			setFSNData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});

		SDEAnalysisApi().then((response) => {
			setSDEData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});

		StockRatioAnalysisApi().then((response) => {
			setStockRData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});

		getTopProductForecastList().then((response) => {
			setTopForecastData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});

    }, [])

    const handleChangeABC = (view_type) => {
		ABCAnalysisApi(view_type).then((response) => {
			setABCData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});
	}

	const handleChangeHML = (view_type) => {
		HMLAnalysisApi(view_type).then((response) => {
			setHMLData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});
	}

	const handleChangeFSN = (view_type) => {
		FSNAnalysisApi(view_type).then((response) => {
			setFSNData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});
	}
	
	const handleChangeStockR = (view_type) => {
		StockRatioAnalysisApi(view_type).then((response) => {
			setStockRData(response.data.data)
		}).catch((error) => {
			console.log(error.response.data.message)
		});
	}

	const monthName = () => {
		const currentDate = new Date();
		const nextMonthDate = new Date(currentDate);
		nextMonthDate.setMonth(currentDate.getMonth() + 1);
		return monthNames[nextMonthDate.getMonth()];
	}

	

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-12 col-lg-6 col-xl-4">
                        {abcData ? 
                        <ABCChart chartData={abcData} handleChange={handleChangeABC} />
                        : null}
					</div>
					
                    <div className="col-md-12 col-lg-6 col-xl-4">
                        {vedData ? 
                        <VEDChart chartData={vedData} />
                        : null}
					</div>

					<div className="col-md-12 col-lg-12 col-xl-4">
					{stockRData ? 
                        <StockRatioChart chartData={stockRData} handleChange={handleChangeStockR} />
                        : null}
					</div>

					<div className="col-xl-4 col-lg-12 col-md-12">
					{fsnData ? 
                        <FSNChart chartData={fsnData} handleChange={handleChangeFSN} />
                        : null}
					</div>

					<div className="col-xl-4 col-lg-6 col-md-12">
					{hmlData ? 
                        <HMLChart chartData={hmlData} handleChange={handleChangeHML} />
                        : null}
					</div>

					<div className="col-xl-4 col-lg-6 col-md-12">
                        {sdeData ? 
                        <SDEChart chartData={sdeData} />
                        : null}
					</div>

					<div className="col-xl-12">
						{topForecastData ? (
							<div className='card'>
								<ForecastChart chartData={topForecastData} title={`Forecasted High-Demand Products for ${monthName()}`}/>
							</div>
						) : null}
					</div>

				</div>
            </div>
		</>
	)
}
export default Analysis;