import React, { useState, useEffect } from 'react';
import { getDesignationList, createDesignation, updateDesignation } from '../../../services/OtherService';
import { toast } from 'react-toastify';
import { Modal, Form } from 'react-bootstrap';


const DesignationPage = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState({ open: false, type: 'Add' });
    const [formData, setFormData] = useState({ name: "" });
    const [data, setData] = useState([])
    const [filterData, setFilterData] = useState([])

    useEffect(() => {
        getDesignationList().then((resp) => {
            setData(resp.data.data)
        }).catch((error) => {
            console.log(error.response.data.message)
        })

    }, [])

    const toggleModal = (type = "Add", item = null) => {
        if (type === "Edit" && item) {
            setFormData({ name: item.label, designation_id: item.value })
        }
        if (isModalOpen.open) {
            setFormData({ name: "" })
        }
        setIsModalOpen({ type, open: !isModalOpen.open })
    }

    const handleSearchChange = (e) => {
        const { value } = e.target
        setSearchQuery(value)
        const filteredData = data.filter((item) =>
            item.label.toLowerCase().includes(value.toLowerCase())
        );
        setFilterData(filteredData)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (isModalOpen.type === "Add") {
            createDesignation(formData).then((resp) => {
                const resp_data = resp.data.data
                setData([resp_data, ...data])
                toast.success(resp.data.message)
                toggleModal()
            }).catch((error) => {
                console.log("error", error)
                toast.error(error.response.data.message)
            });
        } else {
            updateDesignation(formData).then((resp) => {
                const resp_data = resp.data.data
                setData(data.map(item=> item.value === resp_data.value ? {...resp_data} : item) )
                toast.success(resp.data.message)
                toggleModal()
            }).catch((error) => {
                console.log("error", error)
                toast.error(error.response.data.message)
            });
        }
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="d-flex justify-content-between text-wrap align-items-center">
                        <h4 className="heading mb-0">Designation</h4>
                        <button className="btn btn-primary btn-sm ms-1" data-bs-toggle="offcanvas"
                            onClick={ ()=> toggleModal()}
                        >+ Add</button>
                    </div>

                    <div className='form-group mt-3'>
                        <input type="search" name="serach" className="form-control rounded-4 form-control-sm"
                            placeholder="Search" autoFocus
                            value={searchQuery} onChange={handleSearchChange} />
                    </div>
                    <div style={{ height: "400px" }} className="widget-media my-3 dz-scroll height370  ps--active-y">
                        <ul className='timeline'>
                            {searchQuery.length > 0 ? filterData.map((item, index) => (
                                <li key={index} className='my-2'>
                                    <div className='d-flex justify-content-between alogn-items-center'>
                                        <span className='fw-bolder'>{item.label}</span>

                                        <button className='btn btn-primary tp-btn btn-sm' onClick={()=> toggleModal("Edit", item)}><i className='fa fa-edit'></i></button>
                                    </div>
                                </li>
                            )) : data.map((item, index) => (
                                <li key={index} className='my-2'>
                                    <div className='d-flex justify-content-between alogn-items-center'>
                                        <span className='fw-bolder'>{item.label}</span>
                                        <button className='btn btn-primary tp-btn btn-sm' onClick={()=> toggleModal("Edit", item)}><i className='fa fa-edit'></i></button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            {/* add barcode */}
            <Modal className="modal modal-sm fade" id="exampleModal1" show={isModalOpen.open} onHide={toggleModal}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">{isModalOpen.type === "Add" ? "Create" : "Edit"} Designation</h1>
                        <button type="button" className="btn-close" onClick={toggleModal}></button>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={(e) => { handleSubmit(e) }} method='POST'>
                            <div className="row">
                                <div className="col-xl-12">
                                    <label className="form-label">Designation Name<span className="text-danger">*</span></label>
                                    <input type="text"
                                        className="form-control mb-3"
                                        placeholder="Enter Name" required
                                        value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-block btn-sm btn-primary mt-3 float-end">Submit</button>
                        </Form>
                    </div>
                </div>
            </Modal>

            {/* add barcode */}

        </>
    );
};
export default DesignationPage;