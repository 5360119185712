import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Offcanvas } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { getProductLIstAction, loadingToggleAction, modalToggleAction, createProductAction } from '../../../store/actions/ProductActions';
import Pagination from '../../constant/Pagination';
import { getProductCategoryList, getUnitList } from '../../../services/OtherService';
import TableLoaderEffect from '../../constant/TableLoader';
import { toast } from 'react-toastify';

const Products = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [stockType, setStockType] = useState('');
    const recordsPage = 10;
    const dispatch = useDispatch()
    const [formValue, setFormValue] = useState({ name: '', price: '', ucs_code: '', min_threshold: '', max_threshold: '', unit: 'KG', unit_type: 'single', category: '', description: '', sap_description: '', ved_category: 'Vital', lead_time: '' })
    const [categoryList, setCategoryList] = useState([])
    const [unitList, setUnitList] = useState([])

    useEffect(() => {
        dispatch(loadingToggleAction(true))
        dispatch(getProductLIstAction(currentPage, recordsPage, searchQuery, stockType))
    }, [])



    const toggleAddModal = (modal_type) => {
        if (modal_type === "Add") {
            if (props.openModal) {
                setFormValue({ name: '', price: '', ucs_code: '', min_threshold: '', max_threshold: '', unit: '', unit_type: 'single', category: '', description: '', sap_description: '', ved_category: 'Vital', lead_time: '' })
                setCategoryList([])
                setUnitList([])
            } else {
                getProductCategoryList().then(resp => {
                    setCategoryList(resp.data.data)
                }).catch(error => {
                    console.log(error)
                })
                getUnitList().then(resp => {
                    setUnitList(resp.data.data)
                }).catch(error => {
                    console.log(error)
                })
            }
        }
        dispatch(modalToggleAction(modal_type))
    }

    const handleChangeAdd = (e) => {
        const { name, value } = e.target
        setFormValue({ ...formValue, [name]: value })
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        const requiredFieldValue = [formValue.name, formValue.ucs_code, formValue.unit, formValue.min_threshold]
        if (requiredFieldValue.some(itm => itm.length === 0)) {
            toast.error("Required all mandatory fields")
            return false
        } else {
            dispatch(createProductAction(formValue))
        }
    }

    const handlePagination = page => {
        dispatch(loadingToggleAction(true))
        dispatch(getProductLIstAction(page.selected + 1, recordsPage, searchQuery, stockType))
        setCurrentPage(page.selected + 1)
    }

    const handleSearchChange = (e) => {
        const { value } = e.target
        dispatch(getProductLIstAction(currentPage, recordsPage, value, stockType))
        setSearchQuery(value)
    }

    const handleStockChange = (e) => {
        const { value } = e.target
        setStockType(value)
        dispatch(loadingToggleAction(true))
        dispatch(getProductLIstAction(currentPage, recordsPage, searchQuery, value))
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">MaterialS</h4>
                                        <div className='d-flex align-items-center'>
                                            <div className="me-3">
                                                <select className="default-select form-control" name='stock_type' value={stockType} onChange={handleStockChange}>
                                                    <option value="">Select Stock Type</option>
                                                    <option value="Under Stock">Under Stock</option>
                                                    <option value="Over Stock">Over Stock</option>
                                                    <option value="In Stock">In Stock</option>
                                                </select>
                                            </div>
                                            <div className='form-group me-4'>
                                                <input type="search" name="serach" className="form-control "
                                                    placeholder="Search" autoFocus
                                                    value={searchQuery} onChange={handleSearchChange} />
                                            </div>
                                            <button className="btn btn-primary ms-1" data-bs-toggle="offcanvas"
                                                onClick={() => toggleAddModal("Add")}
                                            >Add Material</button>
                                        </div>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Material Name</th>
                                                    <th>UCS Code</th>
                                                    <th>Price</th>
                                                    <th>Quantity</th>
                                                    <th>Min Threshold</th>
                                                    <th>Max Threshold</th>
                                                    <th>Unit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.isLoading ? <TableLoaderEffect colLength={7} /> : props.products.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <Link to={`/material/${item.id}`} className='text-primary fw-bold'>{item.name}</Link>
                                                        </td>
                                                        <td><span>{item.ucs_code}</span></td>
                                                        <td><span>{item.price}</span></td>
                                                        <td><span>{item.net_quantity}</span></td>
                                                        <td><span>{item.min_threshold}</span></td>
                                                        <td><span>{item.max_threshold}</span></td>
                                                        <td><span>{item.unit.name}</span></td>
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>
                                        {props.isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                                            currentPage={currentPage}
                                            recordsPage={recordsPage}
                                            dataLength={props.totals}
                                            handlePagination={handlePagination}
                                        />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* add product modal */}
            <Offcanvas show={props.openModal && props.modalType === "Add"} onHide={() => toggleAddModal("Add")} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title" id="#gridSystemModal">Add Material</h5>
                    <button type="button" className="btn-close"
                        onClick={() => toggleAddModal("Add")}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <div className="container-fluid">
                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Material Name <span className="text-danger">*</span></label>
                                        <input type="text" autoFocus className="form-control" id="name" placeholder="Enter Name"
                                            value={formValue.name} name='name' onChange={handleChangeAdd} required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="ucs_code" className="form-label">UCS Code <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="ucs_code" placeholder="Enter UCS code"
                                            value={formValue.ucs_code} name='ucs_code' onChange={handleChangeAdd} required />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Unit <span className="text-danger">*</span></label>
                                        <select className="default-select form-control" name='unit' value={formValue.unit} onChange={handleChangeAdd} required>
                                            <option value="">Select Unit</option>
                                            {unitList.map((item, index) => (
                                                <option value={item.value} key={index}>{item.label}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="min_threshold" className="form-label">Min Threshold <span className="text-danger">*</span></label>
                                        <input type="number" className="form-control" id="min_threshold" placeholder="Enter Min Threshold"
                                            value={formValue.min_threshold} name='min_threshold' onChange={handleChangeAdd} required />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="max_threshold" className="form-label">Max Threshold </label>
                                        <input type="number" className="form-control" id="max_threshold" placeholder="Enter Max Threshold"
                                            value={formValue.max_threshold} name='max_threshold' onChange={handleChangeAdd} />
                                    </div>

                                    <div className="mb-3">
                                        <label>Unit Item Type</label><br />
                                        <div className="form-check custom-checkbox mb-2 form-check-inline">
                                            <input type="radio" className="form-check-input" defaultChecked
                                                id="unit_type_single" name="unit_type" checked={formValue.unit_type === "single"} value={'single'} onChange={handleChangeAdd} />
                                            <label className="form-check-label" htmlFor="unit_type_single">Single Item</label>
                                        </div>
                                        <div className="form-check custom-checkbox mb-2 form-check-inline">
                                            <input type="radio" className="form-check-input"
                                                id="unit_type_multi" name="unit_type" checked={formValue.unit_type === "multi"} value={'multi'} onChange={handleChangeAdd} />
                                            <label className="form-check-label" htmlFor="unit_type_multi">Multiple Items</label>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xl-6">
                                    <div className="mb-3">
                                        <label htmlFor="price" className="form-label">Price </label>
                                        <input type="number" className="form-control" id="price" placeholder="Enter Price"
                                            value={formValue.price} name='price' onChange={handleChangeAdd} />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">VED Category </label>
                                        <select className="default-select form-control" value={formValue.ved_category} name='ved_category' onChange={handleChangeAdd}>
                                            <option value="Vital">Vital</option>
                                            <option value="Essential">Essential</option>
                                            <option value="Desirable">Desirable</option>
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="price" className="form-label">Lead Time (in Day)  </label>
                                        <input type="number" className="form-control" id="lead_time" placeholder="Enter Lead Time in day"
                                            value={formValue.lead_time} name='lead_time' onChange={handleChangeAdd} />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Material Category </label>
                                        <select className="default-select form-control" name='category' value={formValue.category} onChange={handleChangeAdd}>
                                            <option value="">Select Category</option>
                                            {categoryList.map((item, index) => (
                                                <option value={item.value} key={index}>{item.label}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Description </label>
                                        <textarea style={{ minHeight: "30px" }} className="form-control" placeholder='Enter description' value={formValue.description} name='description' onChange={handleChangeAdd}></textarea>
                                    </div>
                                    <div className="col-xl-12 mb-3">
                                        <label className="form-label">description SAP</label>
                                        <textarea rows="2" className="form-control" placeholder='Enter description SAP' value={formValue.sap_description} name='sap_description' onChange={handleChangeAdd}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <button type="submit" className="btn btn-primary me-1">Submit</button>
                                <button type="button" onClick={() => toggleAddModal("Add")} className="btn btn-danger light ms-1">Cancel</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Offcanvas>

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        products: state.products.products,
        totals: state.products.totals,
        selectedProduct: state.products.selectedProduct,
        isLoading: state.products.isLoading,
        openModal: state.products.isModalOpen,
        modalType: state.products.modalType

    };
};

export default connect(mapStateToProps)(Products);  