import axiosInstance from './AxiosInstance';
const apiUrl = "api/report"

export function getMaterialList(from_date='', to_date='') {
    return axiosInstance.get(`${apiUrl}/material?from_date=${from_date}&to_date=${to_date}`);
}
export function getMaterialTransactionList(from_date='', to_date='', material_id="") {
    return axiosInstance.get(`${apiUrl}/material_transaction?from_date=${from_date}&to_date=${to_date}&material_id=${material_id}`);
}

export function getMostRecieveMoveItemList(from_date='', to_date='', stock_type="stockout") {
    return axiosInstance.get(`${apiUrl}/most_receive_move_item?from_date=${from_date}&to_date=${to_date}&stock_type=${stock_type}`);
}

export function getCriticalReorderMaterial() {
    return axiosInstance.get('api/report/critical_reorder_item');
}

export function getDeadStockItemReportList(from_date='', to_date='') {
    return axiosInstance.get(`${apiUrl}/dead_stock_item?from_date=${from_date}&to_date=${to_date}`);
}

export function searchMaterial() {
    return axiosInstance.get('api/search/material');
}

export function getEmployeeReportList(from_date='', to_date='', employee_id="") {
    return axiosInstance.get(`${apiUrl}/employee?from_date=${from_date}&to_date=${to_date}&employee_id=${employee_id}`);
}

export function getMaterailUsageList(from_date='', to_date='', purpose="") {
    return axiosInstance.get(`${apiUrl}/material_usage?from_date=${from_date}&to_date=${to_date}&purpose=${purpose}`);
}