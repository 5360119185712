export const CREATE_PRODUCT_ACTION = '[Product Action] Create Product';
export const CONFIRMED_CREATE_PRODUCT_ACTION = '[Product Action] Confirmed Create Product';
export const GET_PRODUCT = '[Product Action] Get PRODUCT';
export const CONFIRMED_GET_PRODUCT = '[Product Action] Confirmed Get Product';
export const GET_PRODUCT_LIST = '[Product Action] Get Product List';
export const CONFIRMED_GET_PRODUCT_LIST = '[Product Action] Confirmed Get Product List';
export const EDIT_PRODUCT_ACTION = '[Product Action] Edit Product';
export const CONFIRMED_EDIT_PRODUCT_ACTION = '[Product Action] Confirmed Edit Product';
export const CONFIRMED_DELETE_PRODUCT_ACTION = '[Product Action] Confirmed Delete Product';

export const PRODUCT_STOCK_IN_ACTION = '[Product Action] Product Stock In';
export const CONFIRMED_PRODUCT_STOCK_IN_ACTION = '[Product Action] Confirmed Product Stock In';

export const HANDLE_SELETED_PRODUCT = '[Product Action] Handle Selected Product';
export const LOADING_PRODUCT_ACTION = '[Product Action] Toggle Loading';
export const LOADING_SINGLE_PRODUCT_ACTION = '[Product Action] Toggle Loading Single Product';
export const MODAL_TOGGLE_ACTION = '[Product Action] Toggle Modal';
export const SHOW_TOAST_ERROR = '[Product Action] Show Error';
