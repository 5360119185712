import React, { useState, useEffect } from 'react';
import { getBarcodeList, createBarcode } from '../../../services/ProductService';
import Pagination from '../../constant/Pagination';
import TableLoaderEffect from '../../constant/TableLoader';
import { toast } from 'react-toastify';
import Barcode from 'react-barcode';
import { Modal, Form } from 'react-bootstrap';
import { CancelRequest } from '../../../services/AxiosInstance';
import { Link } from 'react-router-dom';

const statusBtnList = ["All", "Used", "Unused"]

const BarcodePage = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState(statusBtnList[0]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
    const [printCodeList, setPrintCodeList] = useState([])
    const [barcodeForm, setBarcodeForm] = useState({ no_of_barcode: 1, barcode_type: 'product' });
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const recordsPage = 10;

    useEffect(() => {
        setIsLoading(true)
        getBarcodeList(currentPage, recordsPage, searchQuery, statusFilter).then((resp) => {
            setData(resp.data.data)
            setTotal(resp.data.total)
            setIsLoading(false)
        }).catch((error) => {
            console.log(error.response.data.message)
            setIsLoading(false)
        })

    }, [])

    const toggleModal = () => {
        if (isModalOpen) {
            setBarcodeForm({ no_of_barcode: 1, barcode_type: 'product' })
        }
        setIsModalOpen(!isModalOpen)
    }

    const togglePrintModal = (item = null) => {
        if (isPrintModalOpen) {
            setPrintCodeList([])
        }
        if (item) {
            console.log("item", item)
            setPrintCodeList(item)
        }
        setIsPrintModalOpen(!isPrintModalOpen)
    }


    const handleInputChange = (e) => {
        const { name, value } = e.target
        if (name === 'barcode_type') {
            setBarcodeForm({ ...barcodeForm, barcode_type: value })
        } else if (name === 'no_of_barcode') {
            setBarcodeForm({ ...barcodeForm, no_of_barcode: value })
        }
    }

    const handlePagination = (page) => {
        setIsLoading(true)
        const new_page = page.selected + 1
        getBarcodeList(new_page, recordsPage, searchQuery, statusFilter).then((resp) => {
            setData(resp.data.data)
            setTotal(resp.data.total)
            setCurrentPage(new_page)
            setIsLoading(false)
        }).catch((error) => {
            alert(error.response.data.message)
            setIsLoading(false)
        })
    }


    const handleSearchChange = (e) => {
        const { value } = e.target
        if (value.length > 0) {
            CancelRequest()
        }
        getBarcodeList(currentPage, recordsPage, value, statusFilter).then((resp) => {
            if (resp) {
                setData(resp.data.data)
                setTotal(resp.data.total)
            }
        }).catch((error) => {
            console.log(error.response.data.message)
        })
        setSearchQuery(value)
    }

    const handleBarcodeStatus = (status) => {
        getBarcodeList(currentPage, recordsPage, searchQuery, status).then((resp) => {
            if (resp) {
                setData(resp.data.data)
                setTotal(resp.data.total)
            }
        }).catch((error) => {
            console.log(error.response.data.message)
        })
        setStatusFilter(status)
    }

    const Sticker = () => {
        return (
            <div className="sticker" id='sticker'>
                <div className="barcode-container">
                    {printCodeList.map((item, index) => (
                        <div className='barcode'
                            style={{ marignTop: '10px', marginBottom: '10px', pageBreakAfter: 'always' }}
                            key={index}>
                            <Barcode value={item} height={35} width={1.2} />
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    function printDiv() {
        const element = document.getElementById("sticker").innerHTML;
        const printWindow = window.open('', '', 'height=800,width=800');
        printWindow.document.write(element);
        printWindow.document.close();
        // Here is the style you can add to hide the date and title in the print screen.
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = '.department{textAlign:center} @page { size: auto;  margin: 0mm; }';
        // Append the style to the window's body
        printWindow.document.body.appendChild(style);

        printWindow.document.body.setAttribute('style', 'display:flex; justify-content: center; align-items: flex-start;  height: auto;');
        printWindow.print();

        // Add an event listener to the new window
        printWindow.addEventListener('beforeunload', function (event) {
            togglePrintModal()
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        createBarcode(barcodeForm).then((resp) => {
            const barcode_data = resp.data.data
            setData([...barcode_data, ...data])
            setTotal(total + barcode_data.length)
            toast.success(resp.data.message)
            toggleModal()
            const code_list = barcode_data.map(item => {
                return item.barcode_no
            })
            togglePrintModal(code_list)
        }).catch((error) => {
            toast.error(error.response.data.message)
        });


    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">Barcode</h4>
                                        <div className='d-flex align-items-center'>

                                            <div className="btn-group btn-group-sm" role="group" aria-label="Small button group">
                                                {statusBtnList.map(item => (
                                                    <button key={item} type="button"
                                                        className={`btn ${statusFilter === item ? "btn-primary" : "btn-outline-primary"}`}
                                                        onClick={() => handleBarcodeStatus(item)}>{item}</button>
                                                ))}
                                            </div>

                                            <div className='form-group mx-5'>
                                                <input type="search" name="serach" className="form-control "
                                                    placeholder="Search" autoFocus
                                                    value={searchQuery} onChange={handleSearchChange} />
                                            </div>
                                            <button className="btn btn-primary ms-1" data-bs-toggle="offcanvas"
                                                onClick={toggleModal}
                                            > Add Barcode</button>
                                        </div>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Barcode No</th>
                                                    <th>Material/Rack</th>
                                                    <th>Image</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                    <th>Print</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ? <TableLoaderEffect colLength={6} /> : data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><span>{item.barcode_no}</span></td>
                                                        <td>
                                                            {item.product ? <Link to={`/material/${item.product.id}`}>{item.product.name}</Link> : item.rack ? <Link to={`/rack/${item.rack.id}`}>{item.rack.rack_no}</Link> : ''}
                                                        </td>
                                                        <td><Barcode value={item.barcode_no} height={15} width={1.5} /></td>
                                                        <td>
                                                            <span>{item.created_at}</span>
                                                        </td>
                                                        <td>
                                                            {item.status === "Used" ? (
                                                                <span className={`badge light border-0 badge-danger`}>Used</span>
                                                            ) : (
                                                                <span className={`badge light border-0 badge-success`}>Unused</span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <button className="btn btn-outline-primary btn-sm ms-1" data-bs-toggle="offcanvas"
                                                                onClick={() => togglePrintModal([item.barcode_no])}
                                                            >Print</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        {isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                                            currentPage={currentPage}
                                            recordsPage={recordsPage}
                                            dataLength={total}
                                            handlePagination={handlePagination}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* add barcode */}
            <Modal className="modal fade" id="exampleModal1" show={isModalOpen} onHide={toggleModal}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">Create Barcode</h1>
                        <button type="button" className="btn-close" onClick={toggleModal}></button>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={(e) => { handleSubmit(e) }} method='POST'>
                            <div className="row">
                                <div className="col-xl-12">
                                    <label className="form-label">No. Of Barcode<span className="text-danger">*</span></label>
                                    <input type="number"
                                        name="no_of_barcode"
                                        className="form-control mb-3"
                                        placeholder="Enter Number of barcode" required
                                        min={1} max={20}
                                        value={barcodeForm.no_of_barcode} onChange={handleInputChange} />
                                </div>

                                <div className="col-xl-12 my-3">
                                    <label>Barcode Type</label><br />
                                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                                        <input type="radio" className="form-check-input" defaultChecked
                                            id="barcode_prod" name="barcode_type" value={'product'} onChange={handleInputChange} />
                                        <label className="form-check-label" htmlFor="barcode_prod">Material</label>
                                    </div>
                                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                                        <input type="radio" className="form-check-input"
                                            id="barcode_rack" name="barcode_type" value={'rack'} onChange={handleInputChange} />
                                        <label className="form-check-label" htmlFor="barcode_rack">Rack</label>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mt-3 float-end">Submit</button>
                        </Form>
                    </div>
                </div>
            </Modal>

            {/* add barcode */}

            {/* print barcode */}
            <Modal className="modal modal-sm fade" id="exampleModal1" show={isPrintModalOpen} onHide={() => togglePrintModal()}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">Print Barcode</h1>
                        <button type="button" className="btn-close" onClick={() => togglePrintModal()}></button>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex align-items-center justify-content-center">
                            <Sticker />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={printDiv} className="btn btn-primary mt-3 float-end">Print</button>

                    </div>
                </div>
            </Modal>

            {/* print barcode */}
        </>
    );
};
export default BarcodePage;