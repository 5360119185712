import React, { useState } from 'react';
import { stockInProduct, validateProductBarcode } from '../../../services/ProductService';
import { searchRack, searchSource } from '../../../services/RackService';
import { searchMaterial } from '../../../services/ReportService';
import { Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'

function StockInBtn() {
  const emptyStock = {
    barcode_no: '',
    is_valid_barcode: false,
    quantity: '',
    product: null,
    rack: null,
    source: null,
    showRmConf: false
  }
  const [modalOpen, setModalOpen] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [stock, setStock] = useState([emptyStock])

  const [exitModalOpen, setExitModalOpen] = useState(false)
  // source
  const [sourceOption, setSourceOption] = useState([])
  const [isSourceLoading, setIsSourceLoading] = useState(false)

  // material
  const [productOption, setProductOption] = useState([])
  const [isProductLoading, setIsProductLoading] = useState(false)
  // rack
  const [optionRack, SetOptionRack] = useState([])
  const [isRackLoading, setIsRackLoading] = useState(false)


  const toggleExitModal = () => {
    setExitModalOpen(!exitModalOpen)
  }


  const toggleModal = () => {
    if (modalOpen) {
      setStock([emptyStock])
      setSourceOption([])
      setProductOption([])
      SetOptionRack([])
      setExitModalOpen(false)
    } else {
      fetchMaterialOptions()
      fetchRackOptions()
      fetchSourceOptions()
    }
    setModalOpen(!modalOpen)
  }

  const handleInputChange = (value, index) => {
    let newStock = [...stock]
    const elem = document.getElementById(`barcode_no-${index}`)
    newStock[index] = { ...newStock[index], barcode_no: value, is_valid_barcode: false }
    elem?.classList.add('is-invalid')
    setStock([...newStock]);
  };

  const handleQuantityChange = (value, index) => {
    const elem = document.getElementById(`quantity-${index}`)
    if (value === '' || Number(value) > 0) {
      let newStock = [...stock]
      newStock[index] = { ...newStock[index], quantity: Number(value) }
      setStock([...newStock]);
      elem?.classList.remove('is-invalid')
    } else {
      elem?.classList.add('is-invalid')
    }
  };


  const hasDuplicatesStock = () => {
    const idSet = new Set();
    for (const s of stock) {
      if (idSet.has(s.barcode_no)) {
        return true; // Found a duplicate id
      }
      idSet.add(s.barcode_no);
    }
    return false; // No duplicates found
  };

  const handleKeyPress = async (e, index) => {
    if (e.key === 'Enter' && e.target.value.length > 0) {
      if (hasDuplicatesStock()) {
        toast.error("Barcode already exists in stock IN list")
      } else {
        const elem = document.getElementById(`barcode_no-${index}`)
        try {
          await validateProductBarcode(e.target.value);
          let newStock = [...stock]
          newStock[index] = { ...newStock[index], is_valid_barcode: true }
          setStock([...newStock]);
          elem?.classList.remove('is-invalid')
        } catch (error) {
          elem?.classList.add('is-invalid')
          toast.error(error.response.data.message)
        }
      }
    }
  };

  const validateStocks = () => {
    return stock.map((item, index) => {
      const validList = ['barcode_no', 'quantity', 'rack', 'product', 'source']
      return Object.entries(item).map(([key, value]) => {
        if (validList.includes(key)) {
          if (value === null || value.length === 0) {
            const elem = document.getElementById(`${key}-${index}`)
            elem.classList.add('is-invalid')
            elem.focus()
            return false
          }
          return true
        } else if (key === 'is_valid_barcode' && !value) {
          const elem = document.getElementById(`barcode_no-${index}`)
          elem.classList.add('is-invalid')
          elem.focus()
          return false
        }
        return true
      }).every(val => val === true)

    })
  }

  const addStock = () => {
    if (validateStocks().every(value => value === true)) {
      setStock(stock.concat(emptyStock))
    }
  }

  const removeStock = (index) => {
    const newStock = [...stock]
    newStock.splice(index, 1)
    setStock([...newStock])
  }

  const removeConfirm = (index, toggleVal) => {
    const newStock = [...stock]
    newStock[index] = { ...newStock[index], showRmConf: toggleVal }
    setStock([...newStock])
  }

  const onSubmitStockIN = () => {
    if (!isSubmitLoading) {
      if (validateStocks().every(value => value === true)) {
        const data = stock.filter(item => item.quantity > 0 && item.is_valid_barcode).map(st => {
          return { barcode_no: st.barcode_no, quantity: Number(st.quantity), product_id: st.product.value, rack_id: st.rack.value, source: st.source.value }
        })
        console.log("data", data)
        setIsSubmitLoading(true)
        stockInProduct({ stock_list: data }).then((response) => {
          toast.success(response.data.message)
          toggleModal()
        }).catch((error) => {
          toast.error(error.response.data.message)
        });
        setIsSubmitLoading(false)
      }
    }
  }

  const LoadingIndicator = () => {
    return (
      <Spinner color='primary' size={'sm'} delay={0} />
    )
  }

  const handleSourceChange = (data, index) => {
    try {
      let newStock = [...stock]
      newStock[index] = { ...newStock[index], source: { value: data.value, label: data.label } }
      setStock([...newStock]);
      document.getElementById(`source-${index}`)?.classList.remove('is-invalid')
    } catch (error) {
      console.log(error.response.data.message)
    }
  }

  const handleProudctChange = (data, index) => {
    try {
      let newStock = [...stock]
      newStock[index] = { ...newStock[index], product: { value: data.value, label: data.label } }
      setStock([...newStock]);
      document.getElementById(`product-${index}`)?.classList.remove('is-invalid')
    } catch (error) {
      console.log(error.response.data.message)
    }
  }

  const handleRackChange = (data, index) => {
    try {
      let newStock = [...stock]
      newStock[index] = { ...newStock[index], rack: { value: data.value, label: data.label }}
      setStock([...newStock]);
      document.getElementById(`rack-${index}`)?.classList.remove('is-invalid')
    } catch (error) {
      console.log(error.response.data.message)
    }
  }

  async function fetchMaterialOptions() {
    try{
    setIsProductLoading(true)
    const resp = await searchMaterial()
    setProductOption(resp.data.data)
    }
    catch(error){
      console.log("ERROR => ", error)
    } finally{
      setIsProductLoading(false)
    }
  };

  async function fetchRackOptions() {
    try{
    setIsRackLoading(true)
    const resp = await searchRack()
    SetOptionRack(resp.data.data)
    }
    catch(error){
      console.log("ERROR => ", error)
    } finally{
      setIsRackLoading(false)
    }
  };


  async function fetchSourceOptions() {
    try{
    setIsSourceLoading(true)
    const resp = await searchSource()
    setSourceOption(resp.data.data)
    }
    catch(error){
      console.log("ERROR => ", error)
    } finally{
      setIsSourceLoading(false)
    }
  };

  return (
    <>
      <button className="btn btn-success mx-2" onClick={toggleModal}>Stock IN <i className="fa-solid fa-cart-arrow-down ms-2"></i> </button>
      {/* stock out modal */}

      <Modal className="modal fade" id="exampleModal3"
        show={modalOpen}
        onHide={toggleModal}
        fullscreen={true}
        backdrop="static"
        keyboard={false} >
        <div className="modal-content">
          <div className="modal-header ">
            <h3 className="modal-title">Stock IN</h3>
            <button type="button" className="btn btn-danger light btn-sm" onClick={toggleModal}>
              <i className="fa-solid  fa-lg fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className='table-responsive active-projects' style={{ minHeight: '80vh' }}>
              <div id="employee-tbl_wrapper" className="dataTables_wrapper active-projects no-footer">
                <table id="empoloyees-tblwrapper" className="table table-striped ItemsCheckboxSec dataTable no-footer mb-0">
                  <thead>
                    <tr>
                      <th>Material</th>
                      <th>Quantity</th>
                      <th>Rack</th>
                      <th>Source</th>
                      <th>Barcode</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stock.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Select
                            value={item.product}
                            onChange={(data) => handleProudctChange(data, index)}
                            placeholder={"Select Material"}
                            isClearable={false}
                            isLoading={isProductLoading}
                            options={productOption}
                            id={`product-${index}`}
                            className='react-select'
                            classNamePrefix='select'
                            styles={
                              {container: (provided) => ({
                                  ...provided,
                                  width: '20rem', // Set the width of the select container
                                }),
                              }
                            }
                          />
                        </td>
                        <td>
                          <input type="number"
                            min={1}
                            style={{ minWidth: '8rem', maxWidth: '10rem' }}
                            onChange={(e) => { handleQuantityChange(e.target.value, index) }}
                            value={item.quantity}
                            className="form-control" id={`quantity-${index}`} placeholder="Quantity" />
                        </td>
                        <td>
                          <Select
                            value={item.rack}
                            onChange={(data) => handleRackChange(data, index)}
                            placeholder={"Select Rack"}
                            isClearable={false}
                            isLoading={isRackLoading}
                            options={optionRack}
                            id={`rack-${index}`}
                            className='react-select'
                            classNamePrefix='select'
                            styles={
                              {container: (provided) => ({
                                  ...provided,
                                  width: '12rem', // Set the width of the select container
                                }),
                              }
                            }
                          />
                        </td>
                        <td>
                          <CreatableSelect
                            isClearable={false}
                            id={`source-${index}`}
                            value={item.source}
                            className='react-select'
                            classNamePrefix='select'
                            placeholder="Search Source"
                            options={sourceOption}
                            isLoading={isSourceLoading}
                            onChange={(data) => { handleSourceChange(data, index) }}
                            components={{ LoadingIndicator }}
                            styles={
                              {container: (provided) => ({
                                  ...provided,
                                  width: '15rem', // Set the width of the select container
                                }),
                              }
                            }
                          />
                        </td>
                        <td>
                          <input type="text"
                            id={`barcode_no-${index}`}
                            style={{ width: '10rem' }}
                            value={item.barcode_no}
                            onChange={(e) => { handleInputChange(e.target.value, index) }}
                            onKeyPress={(e) => { handleKeyPress(e, index) }}
                            className="form-control" placeholder="Barcode" />
                        </td>
                        <td>
                          {item.is_valid_barcode && (stock.length === index + 1) ? (
                            <button className='btn btn-sm me-2 btn-outline-success rounded' onClick={addStock}><i className="fa-solid fa-lg fa-plus"></i></button>
                          ) : null}
                          {stock.length > 1 ? item.showRmConf ? (
                            <div className="d-flex align-items-center flex-column">
                              <h6>Confirm Remove</h6>
                              <div className='d-flex justify-content-around align-items-center'>
                                <span className='c-pointer badge badge-outline-success badge-sm'
                                  onClick={() => removeStock(index)}>
                                  <i class="fa-solid fa-check"></i>
                                </span>
                                <span className='c-pointer badge badge-outline-danger badge-sm ms-3'
                                  onClick={() => removeConfirm(index, false)}>
                                  <i class="fa-solid fa-xmark"></i>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <button className='btn btn-sm btn-outline-danger rounded'
                              onClick={() => {
                                item.barcode_no.length > 0 ? removeConfirm(index, true) : removeStock(index)
                              }}>
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-primary btn-sm" disabled={isSubmitLoading} onClick={onSubmitStockIN}>
              {isSubmitLoading ? (<>
                <LoadingIndicator /> Stock IN
              </>) : "Stock IN"}
            </button>
            <button type="button" className="btn btn-danger light btn-sm" disabled={isSubmitLoading} onClick={toggleModal}>Close</button>
          </div>
        </div>
      </Modal>
      {/* create emplppyee modal */}
      <Modal className="modal  modal-sm fade" id="exampleModal3"
        show={exitModalOpen}
        onHide={toggleExitModal}
        backdrop="static"
        keyboard={false} >
        <div className="modal-content">
          <div className="modal-header ">
            <h3 className="modal-title">Exit Confirm</h3>
          </div>
          <div className="modal-body">
            <p>You have unsaved changes. Are you sure you want to leave?</p>
            <div>
              <button type="submit" className="btn btn-primary btn-sm me-1" onClick={() => toggleExitModal()} >
                Keep
              </button>
              <button type="button" onClick={() => toggleModal()} className="btn btn-danger  btn-sm light mx-1">Discard</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default StockInBtn;