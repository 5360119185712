import React from "react";
import { connect } from "react-redux";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

/// Css
import "./index.css";


/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";


/// Dashboard
import Home from "./components/Dashboard/Home";
import Rack from "./components/Rack";
import ViewRackProduct from "./components/Rack/viewProduct";
import Product from "./components/Product";
import ViewProduct from "./components/Product/viewProduct";
import StockHistory from "./components/StockHistory";
import ProductHistory from "./components/StockHistory/ProductHistory";
import BarcodePage from "./components/Barcode";
import EmployeePage from "./components/Employee";
import UserPage from "./components/User";
import Settings from "./components/Settings";
import StockReport from "./components/Report";
import ForecastProducts from "./components/ForecastProduct";
import Analysis from "./components/Analysis";

/// Pages
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Login from "./pages/Login"

import logo from '../images/logo/logofullv1.png';

const allroutes = [
  // Dashboard
  { url: "dashboard", element: <Home /> },
  { url: "rack", element: <Rack /> },
  { url: "rack/:id", element: <ViewRackProduct /> },
  { url: "material", element: <Product /> },
  { url: "material/:id", element: <ViewProduct /> },
  { url: "stock_history", element: <StockHistory /> },
  { url: "report", element: <StockReport /> },
  { url: "stock_history/:id", element: <ProductHistory /> },
  { url: "barcode", element: <BarcodePage /> },
  { url: "user", element: <UserPage /> },
  { url: "employee", element: <EmployeePage /> },
  { url: "settings", element: <Settings /> },
  { url: "demand-forecast", element: <ForecastProducts /> },
  { url: "analysis", element: <Analysis /> }
];

const FlashScreen = () => {
  return (
    <div className="flash-screen">
      <div className="flash-body">
        <img src={logo} alt="logo" />
      </div>
    </div>
  )
}

const Markup = ({ isAuthenticated, isCheckingAuth, role, sideMenu }) => {
  const NotFound = () => <Error404 />

  function MainLayout() {
    return (
      <div id="main-wrapper" className={`show ${sideMenu ? "menu-toggle" : ""}`}>
        <Nav />
        <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
          <Outlet />
        </div>
        <Footer />
      </div>
    )
  }

  const HandleUserAuthentication = ({ data }) => {
    if (!isAuthenticated) {
      return <Navigate to='/' />
    } else if (role !== "Admin" && ["user", "settings", "report"].includes(data.url)) {
      return <Error403 />;
    }
    return data.element
  };

  return (isCheckingAuth ? <FlashScreen /> :
    <>
      <Routes>
        <Route path='/' exact element={isAuthenticated ? <Navigate to='/dashboard' /> : <Login />} />
        <Route element={<MainLayout />} >
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={<HandleUserAuthentication data={data} />}
            />
          ))}
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.user?.role || "User",
    sideMenu: state.sideMenu,
    isAuthenticated: state.auth.user,
    isCheckingAuth: state.auth.isCheckingAuth
  };
};

export default connect(mapStateToProps)(Markup);
