import React from "react";
import { useDispatch, useSelector } from 'react-redux';

import { Link } from "react-router-dom";
import { navtoggle } from "../../../store/actions/AuthActions";
import SAILLogo from '../../../images/logo/logo.png';
// import RSPLogo from '../../../images/logo/RSP_logo_text.png';

const NavHader = () => {
  const dispatch = useDispatch();
  const sideMenu = useSelector(state => state.sideMenu);
  const handleToogle = () => {
    dispatch(navtoggle());
  };
  return (
    <>
      <div className="nav-header">
        <Link to="/dashboard" className="brand-logo">
          <img src={SAILLogo} alt="logo" style={{ width: "3rem" }} />
          <span className="brand-title">
            {/* <img src={RSPLogo} alt="logo" style={{ width: "9rem" }} /> */}
            <span className="text-primary">Inteli</span><span className="text-warning">Stock</span>
          </span>
        </Link>
        <div
          className="nav-control"
          onClick={() => {
            handleToogle()
          }}
        >
          <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </div>
      <div className="nav-header-bar"></div>
    </>
  );
};

export default NavHader;
