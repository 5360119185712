import React from "react";
import ReactApexChart from "react-apexcharts";

const ForecastChart = ({ chartData, title }) => {
  const { data, labels } = chartData
  const series = [{ name: 'Stocks', data }]
  const options = {

    chart: {
      height: 300,
      type: 'area',
      stacked: false,
      toolbar: {
        show: false,
      },
    },

    // stroke: {
    //     width: [0, 1, 1],
    //     curve: 'straight',
    //     dashArray: [0, 0, 5]
    // },
    legend: {
      fontSize: '13px',
      fontFamily: 'poppins',
      labels: {
        colors: '#888888',
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '30%',
        borderRadius: 6,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    colors: ["var(--bs-primary)"],
    xaxis: {
      categories: labels,
      type: 'month',
      labels: {
        style: {
          fontSize: '13px',
          colors: '#888888',
        },
      },
    },
    yaxis: {
      min: 0,
      tickAmount: 4,
      labels: {
        style: {
          fontSize: '13px',
          colors: '#888888',
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " Quantity";
          }
          return y;
        }
      }
    }
  }

  return (
    <div className="card">
      <div className="card-header border-0 pb-0 flex-wrap">
        <h3 className="mb-0">{title}</h3>
      </div>
      <div className="card-body  p-0">
        <div id="overiewChart">
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={300}
          />
        </div>
      </div>
    </div>
  );

}

export default ForecastChart;