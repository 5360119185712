import React from 'react';
import AllProjectDonutChart from './AllProjectDonutChart';
import { SVGICON } from '../../../constant/theme';

const CardWidget = ({ data }) => {
    return (
        <>
            <div className="col-xl-3 col-sm-6">
                <div className="card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between style-1">
                            <div>
                                <h6>Today Stock IN</h6>
                                <h3>{data ? data.today_stock_in : <div className="spinner-grow text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>}</h3>
                            </div>
                            <div className="icon-box bg-success-light">
                                <i className="fa-solid fa-cart-arrow-down fa-xl text-success"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-3 col-sm-6">
                <div className="card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between style-1">
                            <div>
                                <h6>Today Stock OUT</h6>
                                <h3>{data ? data.today_stock_out : <div className="spinner-grow text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>}</h3>
                            </div>
                            <div className="icon-box bg-danger-light">
                                <i className="fa-solid fa-square-up-right fa-xl text-danger"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-3 col-sm-6">
                <div className="card same-card">
                    <div className="card-body d-flex align-items-center  py-2">
                        {data ? (<AllProjectDonutChart series={[data.under_stock, data.over_stock, data.in_stock]} />) :
                            <div className="spinner-grow text-primary" role="status" style={{width:'5rem', height:'5rem', marginRight:'2.5rem'}}>
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        <ul className="project-list">
                            <li><h6>All Material Stock</h6></li>
                            <li>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="10" height="10" rx="3" fill="#ff5e5e" />
                                </svg>{" "}
                                Under Stock
                            </li>
                            <li>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="10" height="10" rx="3" fill="#FF9F00" />
                                </svg>{" "}
                                Over Stock
                            </li>
                            <li>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="10" height="10" rx="3" fill="#3AC977" />
                                </svg>{" "}
                                In Stock
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="col-xl-3 col-sm-6 same-card">
                <div className="card">
                    <div className="card-body depostit-card">
                        <div className="depostit-card-media d-flex justify-content-between style-1">
                            <div>
                                <h6>Total Material</h6>
                                <h3>{data ? data.total_product :
                                    <div className="spinner-grow text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>}</h3>
                            </div>
                            <div className="icon-box bg-primary-light">
                                {SVGICON.MenuMaterial}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardWidget;