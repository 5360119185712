import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Logout } from '../../../store/actions/AuthActions';
import { SVGICON } from '../../constant/theme';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';




function LogoutPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [exitModalOpen, setExitModalOpen] = useState(false)

  function onLogout() {
    dispatch(Logout(navigate));
  }
  const toggleExitModal = () => setExitModalOpen(!exitModalOpen)

  return (
    <>
      <Link className="user-profile-item" onClick={toggleExitModal}>
        <span className="user-profile-icon">{SVGICON.Logout} </span>
        <span className="user-profile-text">Logout </span>
      </Link>
      <Modal className="modal  modal-sm fade" id="exampleModal3"
        show={exitModalOpen}
        onHide={toggleExitModal}
         >
        <div className="modal-content">
          <div className="modal-header ">
            <h3 className="modal-title">Logout Confirm</h3>
          </div>
          <div className="modal-body">
            <p className='text-center'>Are you sure you want to logout?</p>
            <div className='d-flex align-items-center justify-content-center'>
              <button type="submit" className="btn btn-outline-primary btn-sm me-1" onClick={onLogout} >
                Yes
              </button>
              <button type="button" onClick={() => toggleExitModal()} className="btn btn-primary  btn-sm  mx-2">No</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default LogoutPage;